<template>
  <v-card class="cardForm">
    <v-toolbar flat dense>
      <v-toolbar-title class="titleForm">Cartas de porte</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon @click = "show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-row dense>
        <v-col lg = "5" class = "myCols">
          <label class="pull-left control-label" style="width: 116px;">
          Número</label>
          <input type="text" style="width: 60px" readonly v-model="item.prefijo"
          class="form-control pull-left input-sm text-right">
          <input type="text" style="width: 90px" readonly v-model = "item.numero"
          class="pull-left form-control input-sm text-right">
        </v-col>
        <v-col lg = "1"></v-col>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label" style="width: 120px;">
          Reempl.</label>
          <input type="text" class="form-control pull-left input-sm text-right"
          style="width: 40px" readonly v-model = "item.prefijoreemplazada">
          <input type="text" class="pull-left form-control input-sm text-right"
          style="width: 80px" readonly v-model = "item.numeroreemplazada">
        </v-col>
      </v-row>
      <v-row dense>
        <v-col lg = "6" class = "myCols text-right">
          <label class="pull-left control-label" style="width: 116px">F. Imput.</label>
          <input type="text"
          class="form-control pull-left input-sm viewFormControlHalfWidth"
          readonly v-model = "item.fechaimputacion">
        </v-col>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label" style="width: 120px">
          CTG</label>
          <input type="text" v-model = "item.nroctg" readonly
          class="form-control pull-left input-sm text-right" style="width: 130px">
        </v-col>
      </v-row>
      <v-row dense>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">
          Titular</label>
          <input type="text" v-model = "item.nombretitular" readonly
          class="form-control pull-left input-sm viewFormControlStWidth">
        </v-col>
      </v-row>
      <v-row dense>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">
          Rte Com Prod</label>
          <input type="text" v-model = "item.nombrertecomprod" readonly
          class="form-control pull-left input-sm viewFormControlStWidth">
        </v-col>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">
          Rte Com Vta Prim</label>
          <input type="text" v-model = "item.nombrertecomvtaprim" readonly
          class="form-control pull-left input-sm viewFormControlStWidth">
        </v-col>
      </v-row>
      <v-row dense>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">
          Rte Com Vta Sec</label>
          <input type="text" v-model = "item.nombrertecomvtasecun" readonly
          class="form-control pull-left input-sm viewFormControlStWidth">
        </v-col>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">
          Rte Com Vta Sec 2</label>
          <input type="text" v-model = "item.nombrertecomvtasecun2" readonly
          class="form-control pull-left input-sm viewFormControlStWidth">
        </v-col>
      </v-row>
      <v-row dense>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">
          Corr Vta Prim</label>
          <input type="text" v-model = "item.nombrecorredorvtaprim" readonly
          class="form-control pull-left input-sm viewFormControlStWidth">
        </v-col>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">
          Corr Vta Sec</label>
          <input type="text" v-model = "item.nombrecorredorvtasecun" readonly
          class="form-control pull-left input-sm viewFormControlStWidth">
        </v-col>
      </v-row>
      <v-row dense>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">
          Destinatario</label>
          <input type="text" v-model = "item.nombredestinatario" readonly
          class="form-control pull-left input-sm viewFormControlStWidth">
        </v-col>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">
          Destino</label>
          <input type="text" v-model = "item.nombredestino" readonly
          class="form-control pull-left input-sm viewFormControlStWidth">
        </v-col>
      </v-row>
      <v-row dense>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">
          Corredor Int.</label>
          <input type="text" v-model = "item.nombrecorreinterno" readonly
          class="form-control pull-left input-sm viewFormControlStWidth">
        </v-col>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">
          N° Recibo</label>
          <input type="text" v-model = "item.nrorecibo" readonly
          class="form-control pull-left input-sm viewFormControlHalfWidth">
          <label class="pull-left control-label viewCPLabelHalf2Width">
          Contrato</label>
          <input type="text" v-model = "item.nrocontrato" readonly
          class="form-control pull-left input-sm viewFormControlHalfWidth">
        </v-col>
      </v-row>
      <v-row dense>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">
          Grano</label>
          <input type="text" v-model = "item.nombregrano" readonly
          class="form-control pull-left input-sm viewFormControlStWidth">
        </v-col>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">Cosecha</label>
          <input type="text" v-model = "item.nombrecosecha" readonly
          class="form-control pull-left input-sm viewFormControlHalfWidth">
          <label class="pull-left control-label viewCPLabelHalf2Width">
          Calidad</label>
          <input type="text" v-model = "item.nombrecalidad" readonly
          class="form-control pull-left input-sm viewFormControlHalfWidth">
        </v-col>
      </v-row><hr>
      <v-row dense>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">
          Loc. Origen</label>
          <input type="text" v-model = "item.nombrelocalidproced" readonly
          class="form-control pull-left input-sm viewFormControlStWidth">
        </v-col>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">
          Km a rec.</label>
          <input type="text" v-model = "item.kmarecorrer" readonly
          class="form-control pull-left input-sm viewFormControlHalfWidth">
          <label class="pull-left control-label viewCPLabelHalf2Width">
          Tarifa Tn.</label>
          <input type="text" v-model = "item.preciotn" readonly
          class="form-control pull-left input-sm viewFormControlHalfWidth">
        </v-col>
      </v-row>
      <v-row dense>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">
          F. Carga</label>
          <input type="text" v-model = "item.fechacarga" readonly
          class="form-control pull-left input-sm viewFormControlHalfWidth">
          <label class="pull-left control-label viewCPLabelHalf2Width">
          Peso Bruto</label>
          <input type="text" v-model = "item.pesobrutocarga" readonly
          class="form-control pull-left input-sm viewFormControlHalfWidth">
        </v-col>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">Tara</label>
          <input type="text" v-model = "item.taracarga" readonly
          class="form-control pull-left input-sm viewFormControlHalfWidth">
          <label class="pull-left control-label viewCPLabelHalf2Width">
          Peso Neto</label>
          <input type="text" v-model = "item.pesonetocarga" readonly
          class="form-control pull-left input-sm viewFormControlHalfWidth">
        </v-col>
      </v-row>
      <v-row dense>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">
          F. Descarga</label>
          <input type="text" v-model = "item.fechadescarga" readonly
          class="form-control pull-left input-sm viewFormControlHalfWidth">
          <label class="pull-left control-label viewCPLabelHalf2Width">
          Peso Bruto</label>
          <input type="text" v-model = "item.pesobrutodescarga" readonly
          class="form-control pull-left input-sm viewFormControlHalfWidth">
        </v-col>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">Tara</label>
          <input type="text" v-model = "item.taradescarga" readonly
          class="form-control pull-left input-sm viewFormControlHalfWidth">
          <label class="pull-left control-label viewCPLabelHalf2Width">
          Peso Neto</label>
          <input type="text" v-model = "item.pesonetodescarga" readonly
          class="form-control pull-left input-sm viewFormControlHalfWidth">
        </v-col>
      </v-row>
      <v-row dense>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">
          Pat/Vag.</label>
          <input type="text" v-model = "item.patentecamion" readonly
          class="form-control pull-left input-sm viewFormControlHalfWidth">
          <label class="pull-left control-label viewCPLabelHalf2Width">
          Pat/Acop.</label>
          <input type="text" v-model = "item.patenteacoplado" readonly
          class="form-control pull-left input-sm viewFormControlHalfWidth">
        </v-col>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">Chofer</label>
          <input type="text" v-model = "item.nombrechofer" readonly
          class="form-control pull-left input-sm viewFormControlStWidth">
        </v-col>
      </v-row>
      <v-row dense>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">
          T. Transp</label>
          <input type="text" v-model = "item.nombretipotransporte" readonly
          class="form-control pull-left input-sm viewFormControlStWidth">
        </v-col>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">
          Transporte</label>
          <input type="text" v-model ="item.nombretransporte" readonly
          class="form-control pull-left input-sm viewFormControlStWidth">
        </v-col>
      </v-row>
      <v-row dense>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">
          Loc. Destino</label>
          <input type="text" v-model = "item.nombrelocaliddestino" readonly
          class="form-control pull-left input-sm viewFormControlStWidth">
        </v-col>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">
          Cupo</label>
          <input type="text" v-model = "item.cupo" readonly
          class="form-control pull-left input-sm viewFormControlStWidth">
        </v-col>
      </v-row><hr>
      <v-row dense>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">% Hdad</label>
          <input type="text" v-model = "item.porchumedad" readonly
          class="form-control pull-left input-sm viewFormControlHalfWidth">
          <label class="pull-left control-label viewCPLabelHalf2Width">
          % M Hdad</label>
          <input type="text" v-model = "item.porcmermahumedad" readonly
          class="form-control pull-left input-sm viewFormControlHalfWidth">
        </v-col>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">
          % Otras M</label>
          <input type="text" v-model = "item.porcotrasmermas" readonly
          class="form-control pull-left input-sm viewFormControlHalfWidth">
          <label class="pull-left control-label viewCPLabelHalf2Width">
          Otras M</label>
          <input type="text" v-model = "item.otrasmermas" readonly
          class="form-control pull-left input-sm viewFormControlHalfWidth">
        </v-col>
      </v-row>
      <v-row dense>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">
          Merma Manip.</label>
          <input type="text" v-model = "item.porcmermamanipuleo" readonly
          class="form-control pull-left input-sm viewFormControlHalfWidth">
          <label class="pull-left control-label" style="width: 90px">
          Merma Hdad</label>
          <input type="text" v-model = "item.mermahumedad" readonly
          class="form-control pull-left input-sm" style="width: 93px">
        </v-col>
        <v-col lg = "6" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">
          Descrip M</label>
          <input type="text" v-model = "item.descripotrasmermas"
          class="form-control pull-left input-sm viewFormControlHalfWidth">
          <label class="pull-left control-label viewCPLabelHalf2Width">
          Neto Final</label>
          <input type="text" v-model = "item.netofinal" readonly
          class="form-control pull-left input-sm viewFormControlHalfWidth">
        </v-col>
      </v-row><br>
      <v-row dense>
        <v-col lg = "12" class = "myCols">
          <label class="pull-left control-label viewCPLabelStWidth">Observaciones</label>
          <textarea class = "form-control input-sm input-text" v-model = "item.observleyenda"
          style="height: 80px;" readonly></textarea>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="show = false" text>Cerrar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style>
  .myCols {
    display: inline-flex;
    padding: 0px !important;
  }

  .pull-left {
    float: left !important;
  }

  .form-control {
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: none;
    border-bottom : 1px solid #4b4b4b;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    padding: 3px 3px;
    height: 21px;
    margin: 0 3px;
    margin-right: 5px;
    display: block;
    font-size: small;
  }

  .input-sm {
    font-size: 12px;
    line-height: 1.5;
  }

  .input-text {
    border: 1px solid #4b4b4b;
    border-radius: 1px;
  }

  label.control-label {
    font-weight: normal;
    text-align: right;
    padding-right: 0;
    margin: 1px 5px 0 0;
    font-size: 12px;
  }

  label.viewCPLabelStWidth {
    width: 130px;
  }

  label.viewCPLabelHalfWidth {
    width: 115px;
  }

  label.viewCPLabelHalf2Width {
    width: 80px;
  }

  input.viewFormControlStWidth {
    width: 325px;
  }

  input.viewFormControlHalfWidth {
    width: 105px;
  }

  hr {
    margin-top: 15px;
    margin-bottom: 5px;
    border: 0px;
    border-top: 2px solid #4b4b4b;
  }

  .titleForm {
    color: black !important;
    font-size: 18px;
  }

  .cardForm {
    background-color: #0246ff;
    opacity: 0.9;
  }
</style>
<script>
export default {
  name: 'Form',
  props: {
    item: {
      Type: Array,
      Required: true,
    },
    value: {
      Type: Boolean,
      Required: true,
    },
  },
  data() {
    return {
      color: '#e8e4e478',
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>
