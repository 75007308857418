<template>
  <v-dialog v-model="dialog" fullscreen>
    <v-card class="d-flex">
      <v-container fill-height fluid class="text-center">
        <v-row align="center" justify="center">
          <v-col>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<style>
  .v-dialog--fullscreen {
    opacity: 0.6;
    border-radius: 0;
    border: none !important
  }
</style>
<script>
export default {
  name: 'MLoading',
  data() {
    return {
      dialog: true,
    };
  },
};
</script>
