<template>
  <v-card>
    <v-toolbar flat dense>
      <v-toolbar-title>Cartas de porte</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon @click = "show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-row dense>
        <v-col cols="12">
          <label class="pull-left control-label d-block"><b>Nro CTG</b></label>
          <b><input type="text" readonly v-model="item.nroctg"
          class="form-control pull-left input-md text-right d-block"></b>
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">Prefijo</label>
          <input type="text" readonly v-model="item.prefijo"
          class="form-control pull-left input-md text-right d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">Número</label>
          <input type="text" readonly v-model="item.numero"
          class="form-control pull-left input-md text-right d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">Prefijo Reempl.</label>
          <input type="text" readonly v-model="item.prefijoreemplazada"
          class="form-control pull-left input-md text-right d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">Número Reempl. </label>
          <input type="text" readonly v-model="item.numeroreemplazada"
          class="form-control pull-left input-md text-right d-block">
        </v-col>
        <v-col cols="12">
          <label class="pull-left control-label d-block">Fecha Imp.</label>
          <input type="text" readonly v-model="item.fechaimputacion"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="12">
          <label class="pull-left control-label d-block">Titular</label>
          <input type="text" readonly v-model="item.nombretitular"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="12">
          <label class="pull-left control-label d-block">Rte Com Prod</label>
          <input type="text" readonly v-model="item.nombrertecomprod"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="12">
          <label class="pull-left control-label d-block">Rte Com Vta Prim</label>
          <input type="text" readonly v-model="item.nombrertecomvtaprim"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="12">
          <label class="pull-left control-label d-block">Rte Com Vta Sec</label>
          <input type="text" readonly v-model="item.nombrertecomvtasecun"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="12">
          <label class="pull-left control-label d-block">Rte Com Vta Sec 2</label>
          <input type="text" readonly v-model="item.nombrertecomvtasecun2"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="12">
          <label class="pull-left control-label d-block">Corr Vta Prim</label>
          <input type="text" readonly v-model="item.nombrecorredorvtaprim"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="12">
          <label class="pull-left control-label d-block">Corr Vta Sec</label>
          <input type="text" readonly v-model="item.nombrecorredorvtasecun"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="12">
          <label class="pull-left control-label d-block">Destinatario</label>
          <input type="text" readonly v-model="item.nombredestinatario"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="12">
          <label class="pull-left control-label d-block">Destino</label>
          <input type="text" readonly v-model="item.nombredestino"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="12">
          <label class="pull-left control-label d-block">Corredor Int.</label>
          <input type="text" readonly v-model="item.nombrecorreinterno"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">N° Recibo</label>
          <input type="text" readonly v-model="item.nrorecibo"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">Contrato</label>
          <input type="text" readonly v-model="item.nrocontrato"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">Grano</label>
          <input type="text" readonly v-model="item.nombregrano"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">Cosecha</label>
          <input type="text" readonly v-model="item.nombrecosecha"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="12">
          <label class="pull-left control-label d-block">Calidad</label>
          <input type="text" readonly v-model="item.nombrecalidad"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="12">
          <label class="pull-left control-label d-block">Loc. Origen</label>
          <input type="text" readonly v-model="item.nombrelocalidproced"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">Km a rec.</label>
          <input type="text" readonly v-model="item.kmarecorrer"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">Tarifa Tn.</label>
          <input type="text" readonly v-model="item.preciotn"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">Fecha Carga</label>
          <input type="text" readonly v-model="item.fechacarga"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">Peso bruto</label>
          <input type="text" readonly v-model="item.pesobrutocarga"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">Tara</label>
          <input type="text" readonly v-model="item.taracarga"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">Peso neto</label>
          <input type="text" readonly v-model="item.pesonetocarga"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">Fecha Descarga</label>
          <input type="text" readonly v-model="item.fechadescarga"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">Peso bruto</label>
          <input type="text" readonly v-model="item.pesobrutodescarga"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">Tara</label>
          <input type="text" readonly v-model="item.taradescarga"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">Peso neto</label>
          <input type="text" readonly v-model="item.pesonetodescarga"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">Pat/Vag</label>
          <input type="text" readonly v-model="item.patentecamion"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">Pat/Acop.</label>
          <input type="text" readonly v-model="item.patenteacoplado"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="12">
          <label class="pull-left control-label d-block">Chofer</label>
          <input type="text" readonly v-model="item.nombrechofer"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="12">
          <label class="pull-left control-label d-block">Tipo Transp</label>
          <input type="text" readonly v-model="item.nombretipotransporte"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="12">
          <label class="pull-left control-label d-block">Transporte</label>
          <input type="text" readonly v-model="item.nombretransporte"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="12">
          <label class="pull-left control-label d-block">Loc. Destino</label>
          <input type="text" readonly v-model="item.nombrelocaliddestino"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="12">
          <label class="pull-left control-label d-block">Cupo</label>
          <input type="text" readonly v-model="item.cupo"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">% Hdad</label>
          <input type="text" readonly v-model="item.porchumedad"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">% Merma Hdad</label>
          <input type="text" readonly v-model="item.porcmermahumedad"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">% Otras Merma</label>
          <input type="text" readonly v-model="item.porcotrasmermas"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">Otras Merma</label>
          <input type="text" readonly v-model="item.otrasmermas"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">Merma Manip.</label>
          <input type="text" readonly v-model="item.porcmermamanipuleo"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">Merma Hdad</label>
          <input type="text" readonly v-model="item.mermahumedad"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">Descrip Merma</label>
          <input type="text" readonly v-model="item.descripotrasmermas"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="6">
          <label class="pull-left control-label d-block">Neto final</label>
          <input type="text" readonly v-model="item.netofinal"
          class="form-control pull-left input-md d-block">
        </v-col>
        <v-col cols="12">
          <label class="pull-left control-label d-block">Observaciones</label>
          <textarea class="form-control input-sm input-text" auto-grow
          v-model="item.observleyenda" readonly style="height: max-content"
          rows="5"></textarea>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="show = false">Cerrar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style>
.input-md {
  font-size: 16px;
  line-height: 1.5;
}
</style>
<script>
export default {
  name: 'FormMobile',
  props: {
    item: {
      Type: Array,
      Required: true,
    },
    value: {
      Type: Boolean,
      Required: true,
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  data() {
    return {
      datos: [
        { label: 'Prefijo', cols: '4', model: this.item.prefijo },
        { label: 'Número', cols: '8', model: this.$props.item.numero },
        { label: 'Prefijo Reempl.', cols: '4', model: this.$props.item.prefijoreemplazada },
        { label: 'Número Reempl.', cols: '8', model: this.$props.item.numeroreemplazada },
        { label: 'F. Imp', cols: '6', model: this.$props.item.fechaimputacion },
        { label: 'F. Venc', cols: '6', model: this.$props.item.fechavencim },
        { label: 'C.E.E', cols: '12', model: this.$props.item.cee },
        { label: 'Remitente', cols: '12', model: this.$props.item.nombreremitente },
        { label: 'Intermediario', cols: '12', model: this.$props.item.nombreintermediario },
        { label: 'Rem. Comercial', cols: '12', model: this.$props.item.nombreremitentecomercial },
        { label: 'Corredor', cols: '12', model: this.$props.item.nombrecorredor },
        { label: 'Destinatario', cols: '12', model: this.$props.item.nombredestinatario },
        { label: 'Corredor Int.', cols: '12', model: this.$props.item.nombrecorreinterno },
        { label: 'Destino', cols: '12', model: this.$props.item.nombredestino },
        { label: 'Nro Recibo', cols: '12', model: this.$props.item.nrorecibo },
        { label: 'Contrato', cols: '6', model: this.$props.item.nrocontrato },
        { label: 'CTG', cols: '6', model: this.$props.item.nroctg },
        { label: 'Grano', cols: '6', model: this.$props.item.nombregrano },
        { label: 'Cosecha', cols: '6', model: this.$props.item.nombrecosecha },
        { label: 'Calidad', cols: '6', model: this.$props.item.nombrecalidad },
        { label: 'Loc. Origen', cols: '12', model: this.$props.item.nombrelocalidproced },
        { label: 'KM a rec.', cols: '6', model: this.$props.item.kmarecorrer },
        { label: 'Tarifa Tn.', cols: '6', model: this.$props.item.preciotn },
        { label: 'F. Carga', cols: '6', model: this.$props.item.fechacarga },
        { label: 'Peso bruto', cols: '6', model: this.$props.item.pesobrutocarga },
        { label: 'Tara', cols: '6', model: this.$props.item.taracarga },
        { label: 'Peso Neto', cols: '6', model: this.$props.item.pesonetocarga },
        { label: 'F. Descarga', cols: '6', model: this.$props.item.fechadescarga },
        { label: 'Peso bruto', cols: '6', model: this.$props.item.pesobrutodescarga },
        { label: 'Tara', cols: '6', model: this.$props.item.taradescarga },
        { label: 'Peso Neto', cols: '6', model: this.$props.item.pesonetodescarga },
        { label: 'Pat/Vag', cols: '6', model: this.$props.item.patentecamion },
        { label: 'Pat/Acop.', cols: '6', model: this.$props.item.patenteacoplado },
        { label: 'Chofer', cols: '12', model: this.$props.item.nombrechofer },
        { label: 'T. Transp.', cols: '12', model: this.$props.item.nombretipotransporte },
        { label: 'Transporte', cols: '12', model: this.$props.item.nombretransporte },
        { label: 'Loc. Destino', cols: '12', model: this.$props.item.nombreLOCALIDDESTINO },
        { label: '% Hdad.', cols: '6', model: this.$props.item.porchumedad },
        { label: '% M Hdad', cols: '6', model: this.$props.item.PORCMERMAHUMEDAD },
        { label: '% Otras M', cols: '6', model: this.$props.item.porcotrasmermas },
        { label: 'Otras M', cols: '6', model: this.$props.item.otrasmermas },
        { label: 'Merma Manip.', cols: '6', model: this.$props.item.porcmermamanipuleo },
        { label: 'Merma Hdad.', cols: '6', model: this.$props.item.MERMAHUMEDAD },
        { label: 'Descrip M', cols: '6', model: this.$props.item.DESCRIPOTRASMERMAS },
        { label: 'Neto final', cols: '6', model: this.$props.item.netofinal },
      ],
    };
  },
};
</script>
