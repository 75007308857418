<template>
  <v-app>
    <v-toolbar color="trasparent" class="slide mt-5" flat>
     <v-row>
      <v-col cols="1" class="d-none d-md-block"></v-col>
      <v-col :cols="(esMobile?12:3)">
        <v-img src = "@/assets/Weiman_logotipo.svg">
        </v-img>
      </v-col>
      <v-col cols="12" class="d-flex d-sm-none text-center">
          <v-menu offset-y dark>
            <template v-slot:activator="{ on }">
                <v-btn absolute icon x-large color="#ffbb00" class="mx-auto" v-on="on"
                  style="left:0; right: 0;">
                  <v-icon>mdi-menu</v-icon></v-btn>
            </template>
            <v-list>
                <v-list-item color="white" href="/">Inicio</v-list-item>
                <v-list-item color="#ffbb00" href="/institucional">Institucional</v-list-item>
                <v-list-item color="white" href="/servicios">Servicios</v-list-item>
                <v-list-item color="white" href="/contacto">Contacto</v-list-item>
                <v-list-item color="#ffbb00" href="/login">Acceso a clientes</v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      <v-col cols="8" class="mt-10 d-none d-sm-flex">
        <v-spacer></v-spacer>
        <v-btn text color="white" href="/">Inicio</v-btn>
        <v-btn text color="white" href="/institucional">Institucional</v-btn>
        <v-btn text color="white" href="/servicios">Servicios</v-btn>
        <v-btn text color="white" href="/contacto">Contacto</v-btn>
        <v-btn text color="#ffbb00" href="/login">Acceso a clientes
          <v-icon right>mdi-account-group</v-icon>
        </v-btn>
      </v-col>
     </v-row>
    </v-toolbar>
    <v-carousel height="600" cycle :show-arrows="false"
      hide-delimiter-background value="1">
      <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
        :src="item.src"
      >
        <div class="contenedor">
          <div class="titleWhite">
            CONFIANZA, COMPROMISO, <br> RESPONSABILIDAD<br>
          </div>
          <div class="titleOrange">
            SON NUESTROS PILARES
          </div>
          <v-btn rounded class="px-10" color="#4b4b4b" href="/institucional">
            VER MÁS <v-icon right>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
      </v-carousel-item>
    </v-carousel>
    <div style="background-color: white">
      <v-col cols="12" class="text-center mb-10 tituloFlecha">
        <v-img
          src="@/assets/iconoFlecha.svg"
          :aspect-ratio = "esMobile?10:30"
          contain
        ></v-img>
        <hr class="vr mx-auto">
        <v-btn rounded class="btnServicios px-10">Nuestros servicios</v-btn>
      </v-col>
      <v-row class="rowCenter">
        <v-item-group>
          <v-col
            v-for= "(item, i) in servicios"
            :key="i"
            :cols="(esMobile) ? 6 : 2"
            :class="esMobile? 'ma-0 px-0 py-4 colsCenter' : 'ma-4 pa-4 colsCenter'"
          >
            <v-item>
              <v-card class="mx-auto" flat max-width="80%">
                <v-img
                  :src= "item.src"
                  height="80" contain
                ></v-img>
                <p class="textCard pt-2">{{ item.text }}</p>
                <v-card-actions>
                  <v-btn rounded class="px-10 mt-2 btnCard mx-auto" dark color="#4b4b4b"
                  :href="'servicios/#' + item.ref">
                  ver más</v-btn>
                </v-card-actions>
              </v-card>
            </v-item>
          </v-col>
        </v-item-group>
      </v-row>
    </div>
    <v-row class="rowCenter greyBackground" style="width: auto;">
      <v-col :cols="(esMobile) ? 12 : 5" class="colsCenter">
        <v-card :class="esMobile?'mx-8':'mx-auto'" flat>
          <v-img :height="esMobile?200:350"
            src="@/assets/main/cartaHome.png"
          ></v-img>
          <v-card-text class="cardTextHome">
            <div :style="esMobile?'font-size:18px':'font-size:22px'">
            CONFECCIÓN DE CARTA DE PORTE</div>
            <div class="my-3">Completa tu carta de porte de forma rápida y sencilla</div>
          </v-card-text>
          <v-card-actions class="justify-center greyBackground">
            <v-btn rounded class="px-8 mt-2 btnCard" dark color="#4b4b4b"
            href="/login">
            ver más</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col :cols="(esMobile) ? 12 : 5" class="colsCenter">
        <v-card :class="esMobile?'mx-8 mt-12':'mx-auto mt-12'" flat>
          <v-img :height="esMobile?200:350"
            src="@/assets/main/reciboHome.png"
          ></v-img>
          <v-card-text class="cardTextHome">
            <div :style="esMobile?'font-size:18px':'font-size:22px'">CONDICIONES DE RECIBO</div>
            <div class="my-3">Conocé las calidades exigidas en cada puerto</div>
          </v-card-text>
          <v-card-actions class="justify-center greyBackground">
            <v-btn rounded class="px-8 mt-2 btnCard" dark color="#4b4b4b">
            ver más</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-center tituloFlecha d-none d-sm-flex">
        <v-img
          src="@/assets/iconoFlecha.svg"
          :aspect-ratio = "esMobile?10:30"
          contain
        ></v-img>
      </v-col>
      <v-col cols="12" class="text-center mt-10 pb-0">
        <v-btn rounded class="btnServicios px-10">Contacto</v-btn>
        <hr class="vr mx-auto">
      </v-col>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6222.811222380937!2d-62.20494297314262!3d-38.75439994378339!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95eda265beb0b0a9%3A0x123b3dff53b99ff7!2sEl%20Tri%C3%A1ngulo%20-%20Parada%20de%20Camiones!5e0!3m2!1ses-419!2sar!4v1612535791513!5m2!1ses-419!2sar" width="800" height="200" frameborder="0" style="border:0; width: 100%" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    </v-row>
    <Footer/>
  </v-app>
</template>
<style>
  .slide {
    background-color: transparent !important;
    position: absolute;
    z-index: 100;
    width: 100%;
  }
  .v-item--active .v-btn__content .v-icon {
    color: #ffbb00;
    opacity: 0.9;
  }
  .v-carousel__controls__item::before{
    background-color: transparent !important;
  }
  .contenedor {
    position: absolute;
    top: 40%;
    font-size: 2rem;
    margin-left: 3em;
  }
  .titleWhite {
    color: white;
  }
  .titleOrange {
    color:  #ffbb00;
  }
  .textCard {
    font-size: 14px;
    text-align: center;
    color: #0246ff;
    line-height: 1.125;
    height: 2.25em;
  }
  .btnCard {
    font-size: x-small !important;
    color: #b0abab !important;
    text-transform: lowercase !important;
  }
  .colsCenter {
    display: inline-block;
    float: none;
  }

  .rowCenter {
    text-align: center;
    display: inline-block;
    float: none;
    width: 100%;
  }

  .btnServicios {
    font-size: 18px !important;
    color: #ffbb00 !important;
    background-color: white !important;
    border: 1px solid #4b4b4b !important;
    font-weight: bold;
  }

  .cardTextHome {
    background-color: #0246ff;
    color: white !important;
  }

  .greyBackground {
    background-color: rgb(240, 239, 239) !important;
  }

  .v-carousel__controls {
    height: 120px !important;
  }

  @media screen and (max-width: 600px) {
  .contenedor {
    position: absolute;
    top: 40%;
    font-size: 1.6rem;
    margin-left: 1em;
  }
  }
</style>
<script>
import Footer from '@/components/General/Footer.vue';

export default {
  name: 'Principal',
  components: {
    Footer,
  },
  data() {
    return {
      esMobile: this.isMobile(),
      items: [
        /* eslint-disable global-require */
        { src: this.isMobile() ? require('@/assets/main/mobile/Slide1.png') : require('@/assets/main/Slide1.png') },
        { src: this.isMobile() ? require('@/assets/main/mobile/Slide2.png') : require('@/assets/main/Slide2.png') },
        { src: this.isMobile() ? require('@/assets/main/mobile/Slide3.png') : require('@/assets/main/Slide3.png') },
      ],
      servicios: [
        /* eslint-disable global-require */
        {
          src: require('@/assets/main/entregasEmbarque.svg'),
          text: 'ENTREGAS Y EMBARQUES',
          ref: 'ent',
        },
        {
          src: require('@/assets/main/asesoramientoPersonalizado.svg'),
          text: 'ASESORAMIENTO PERSONALIZADO',
          ref: 'ases',
        },
        {
          src: require('@/assets/main/atencion24hs.svg'),
          text: 'ATENCIÓN LAS 24 HORAS',
          ref: 'aten',
        },
        {
          src: require('@/assets/main/controlDoc.svg'),
          text: 'CONTROL DE DOCUMENTACIÓN Y CUPO',
          ref: 'control',
        },
      ],
    };
  },
  methods: {
    isMobile() {
      if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || (window.innerWidth < 1000)) {
        return true;
      }
      return false;
    },
  },
};
</script>
