<template >
  <v-container fill-height fluid>
    <v-row align="center" :class="esMobile?'':'ma-0'">
      <v-col md="6" sm = "12">
        <v-card :max-width="esMobile?'100%':'80%'"
          dark
          class="mx-auto card-login">
          <v-card-text class="px-10 pb-0">
            <v-row justify="center">
              <v-img src = "@/assets/Weiman_logotipo.svg"
              contain max-width="80%"
              ></v-img>
            </v-row>
            <v-row justify="center" class="pa-8 ma-0">
                <v-text-field
                  label = "Usuario"
                  v-model = "cuitValue"
                  maxlength="15" class="text"
                >
                </v-text-field>
                <v-text-field class="text"
                label = "Contraseña"
                  v-model = "pass"
                  type = "password"
                  @keydown.enter = "ingresar"
                  style = "margin-top: 0px; padding-top: 0px;"
                ></v-text-field>
                <v-btn class = "mx-auto px-10" @click = "ingresar"
                  color = "#515151" rounded
                >Ingresar
                  <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
                <v-btn text small @click = "dialog = true" color="#ffbb00" class="mt-8">
                No recuerdo mi contraseña</v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model = "dialog" max-width = "490">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            Solicitar cambio de contraseña
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click = "dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text><br>
          <p>
          Comuníquese con la Administración para solicitar una nueva contraseña</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click = "dialog = false" outlined>Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<style>
.card-login {
  opacity: 0.7;
  border-radius: 50px !important;
  float: right;
}
.text  input, .text.v-input input:focus,.text.v-input input:active {
  border-bottom: 1px solid #ffbb00 !important;
  outline: #ffbb00 !important
}

</style>
<script>
import axios from 'axios';
import url from '@/var.js';

export default {
  name: 'Login',
  title: 'Pablo Weiman - Ingreso',
  data() {
    return {
      cuitValue: '',
      pass: '',
      dialog: false,
      logo: '@/assets/logoWeb.png',
      esMobile: this.isMobile(),
    };
  },
  methods: {
    ingresar() {
      axios.post(`${url}/login`, {
        cuit: this.cuitValue,
        pass: this.pass,
      })
        .then((response) => {
          if (response.status === 200) {
            if (response.data.msg === 'Ok') {
              this.$session.start();
              this.$session.set('id', response.data.result);
              this.$session.set('token', response.data.token);
              this.$router.push('/inicio');
              this.$router.go(0);
            } else {
              this.$fire({
                title: 'Atención',
                text: 'El usuario y/o contraseña no son correctos.',
                type: 'error',
              });
            }
          }
        });
    },
    isMobile() {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    },
  },
};
</script>
