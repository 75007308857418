<template>
  <v-app>
    <v-toolbar color="#000000" :src="img" extended
      height="400" dark prominent>
      <v-row>
          <v-col cols="1" class="d-none d-md-block"></v-col>
          <v-col :cols="(esMobile?12:3)">
            <v-img src = "@/assets/Weiman_logotipo.svg">
            </v-img>
          </v-col>
          <v-col cols="12" class="d-flex d-sm-none text-center">
          <v-menu offset-y dark>
            <template v-slot:activator="{ on }">
                <v-btn absolute icon x-large color="#ffbb00" class="mx-auto" v-on="on"
                  style="left:0; right: 0;">
                  <v-icon>mdi-menu</v-icon></v-btn>
            </template>
            <v-list>
                <v-list-item color="white" href="/">Inicio</v-list-item>
                <v-list-item color="#ffbb00" href="/institucional">Institucional</v-list-item>
                <v-list-item color="white" href="/servicios">Servicios</v-list-item>
                <v-list-item color="white" href="/contacto">Contacto</v-list-item>
                <v-list-item color="#ffbb00" href="/login">Acceso a clientes</v-list-item>
            </v-list>
          </v-menu>
          </v-col>
          <v-col cols="8" class="mt-10 d-none d-sm-flex">
            <v-spacer></v-spacer>
            <v-btn text color="white" href="/">Inicio</v-btn>
            <v-btn text color="white" href="/institucional">Institucional</v-btn>
            <v-btn text color="white" href="/servicios">Servicios</v-btn>
            <v-btn text color="#ffbb00" href="/contacto">Contacto</v-btn>
            <v-btn text color="#ffbb00" href="/login">Acceso a clientes
              <v-icon right>mdi-account-group</v-icon>
            </v-btn>
          </v-col>
      </v-row>
    </v-toolbar>
     <div style="background-color: white" class="mb-8">
      <v-col cols="12" class="text-center mb-10 tituloFlecha">
        <v-img
          src="@/assets/iconoFlecha.svg"
          :aspect-ratio = "esMobile?10:30"
          contain
        ></v-img>
        <hr class="vr mx-auto">
        <v-btn rounded class="btnServicios px-10">Nuestras oficinas</v-btn>
      </v-col>
      <v-card class="mx-auto" flat max-width="80%">
        <v-row>
          <v-col :cols="(esMobile) ? 12 : 6" class="text-center">
            <span class="titleContacto">ADMINISTRACIÓN:</span>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3113.2551434649135!2d-62.27701378465507!3d-38.711950779599675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95edbca7d0c47d91%3A0xab1789b135ca8a32!2sEstomba%20754%2C%20B8000AAP%20Bah%C3%ADa%20Blanca%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1631192910066!5m2!1ses-419!2sar" :width="(esMobile) ? 400 : 450" :height="(esMobile) ? 300 : 400" style="border: none; border-top:3px solid #ffbb00;" allowfullscreen="" loading="lazy"></iframe>
            <div style="display: flex; justify-content:center">
              <p style="color: #0246ff" class="text-left">
                Estomba 754 - 8000<br>
                Bahía Blanca, Buenos Aires, Argentina.<br>
                Tel: (291) 453-6801 || (291) 570-0721<br>
                <a href="mailto:administracion@weimanentregas.com.ar">
                administracion@weimanentregas.com.ar</a><br>
                <a href="mailto:pweiman@weimanentregas.com.ar">
                pweiman@weimanentregas.com.ar</a>
              </p>
            </div>
          </v-col>
          <v-col :cols="(esMobile) ? 12 : 6" class="text-center">
            <span class="titleContacto">PLAYA DE CAMIONES:</span>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3700.02576302536!2d-62.207574190236926!3d-38.75594177901716!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95eda265beb0b0a9%3A0x123b3dff53b99ff7!2sEl%20Tri%C3%A1ngulo%20-%20Parada%20de%20Camiones!5e0!3m2!1ses-419!2sar!4v1616073881345!5m2!1ses-419!2sar" :width="(esMobile) ? 400 :450" :height="(esMobile) ? 300 : 400" style="border: none; border-top:3px solid #ffbb00;" allowfullscreen="" loading="lazy"></iframe>
            <div style="display: flex; justify-content:center">
              <p style="color: #0246ff" class="text-left">
                Ruta 3 Norte y Acceso a Puertos<br>
                Playa El Triángulo Oficina 4 <br>
                Bahía Blanca, Buenos Aires, Argentina.<br>
                Tel: (291) 485-4531
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <Footer/>
  </v-app>
</template>
<style>
.centerButton {
  left: 50%;
}
.titleContacto {
  color: #ffbb00;
  font-size: x-large;
  font-weight: 450;
}
</style>
<script>
import Footer from '@/components/General/Footer.vue';

export default {
  name: 'Contacto',
  components: {
    Footer,
  },
  data() {
    return {
      esMobile: this.isMobile(),
      /* eslint-disable global-require */
      img: this.isMobile() ? require('@/assets/contacto/mobile/slide.png') : require('@/assets/contacto/slide.png'),
      /* eslint-enable global-require */
    };
  },
  methods: {
    isMobile() {
      if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || (window.innerWidth < 1000)) {
        return true;
      }
      return false;
    },
  },
};
</script>
