<template>
  <v-footer color="#4b4b4b" dark app>
    <v-col class = "text-center footText" cols= "12">
        <strong>WEIMAN - SOLUCIONES PARA EL AGRO </strong>
       <span>| {{direccion}}
       | 8000 Bahía Blanca
       | Bs As, Argentina <br />
         {{ telefonos}}
       | mail: <a :href=linkToEmail>{{email}}
       </a></span><br>
      <small style="font-size: 10px">Desarrollado por Threads S.R.L <a href="http://www.threads-srl.com.ar">www.threads-srl.com.ar</a></small>
    </v-col>
  </v-footer>
</template>
<style>
.footText {
  font-size: small;
  color: white
}
.footText span, .footText a {
  color: rgba(255,255,255, 0.7);
}
</style>
<script>
import axios from 'axios';
import url from '@/var.js';

export default {
  name: 'Footer',
  data() {
    return {
      direccion: '',
      telefonos: '',
      email: '',
      linkToEmail: '',
    };
  },
  methods: {
    cargarDatosConfig() {
      const path = `${url}/cargarDatosConfig`;
      axios.post(path, {
        id: this.$session.get('id'),
        token: this.$session.get('token'),
      })
        .then((response) => {
          this.direccion = response.data.direccion;
          this.email = response.data.email;
          this.telefonos = response.data.telefonos;
          this.linkToEmail = `mailto:${response.data.email}`;
        });
    },
  },
  mounted() {
    this.cargarDatosConfig();
  },
};
</script>
