<template>
  <v-footer color="#4b4b4b" dark>
    <v-btn
      fab
      dark
      fixed
      bottom
      right
       icon class="mb-10 mr-4"
      href="https://wa.me/542915700721"
    ><v-img src="@/assets/iconoWhatsapp.svg" aspect-ratio="0.1" contain></v-img>
    </v-btn>
    <v-row>
      <v-col lg="4" class="d-none d-sm-flex">
        <v-img src = "@/assets/Weiman_logotipo.svg" height="60" contain></v-img>
      </v-col>
      <v-col lg="5" sm="12" style="font-size:14px">
        <div>
          <b>Administración:</b><br>
          Estomba 754 - 8000 Bahía Blanca<br>
          Buenos Aires, Argentina. <br>
          Tel: (291) 453-6801 || (291) 570-0721
          <a href= "mailto:administracion@pabloweiman.com.ar">
          administracion@pabloweiman.com.ar</a><br>
          <a href = "mailto:administracion@weimanentregas.com.ar">
          administracion@weimanentregas.com.ar</a><br>
        </div>
        <div><br>
          <b>Playa de camiones:</b><br>
          Ruta 3 Norte y Acceso a Puertos Playa El Triángulo<br>
          Oficina 4 Bahía Blanca, Buenos Aires, Argentina<br>
          Tel: (291) 485-4531<br>
        </div>
      </v-col>
      <v-col lg="3" sm="12" class="d-sm-flex">
        <v-img src = "@/assets/Weiman_logotipo.svg" height="50" max-width="60%" contain
        class="d-flex d-sm-none"></v-img>
        <v-btn icon color="#ffbb00" bottom :class="esMobile?'mt-3':'iconLarge ml-10'"
        ><v-icon :size="esMobile?30:40">mdi-twitter</v-icon></v-btn>
        <v-btn icon color="#ffbb00" bottom :class="esMobile?'mt-3 ml-3':'iconLarge ml-3'"
        ><v-icon :size="esMobile?30:40">mdi-instagram</v-icon></v-btn>
      </v-col>
    </v-row>
  </v-footer>
</template>
<style>
.iconLarge {
  top: 70%;

}
</style>
<script>
export default {
  name: 'Footer',
  data() {
    return {
      esMobile: this.isMobile(),
    };
  },
  methods: {
    isMobile() {
      if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || (window.innerWidth < 1000)) {
        return true;
      }
      return false;
    },
  },
};
</script>
