<template>
  <v-container>
    <v-card class="mx-auto cardInteract mb-2"
    :width="(esMobile)? '100%' : '80%'">
    <v-card-title>
      Datos de la carta de porte para el Transporte Automotor
      <v-spacer></v-spacer>
      <v-btn small rounded class="px-5" color="#4b4b4b" dark
      @click="vaciarForm()">VACIAR FORM</v-btn>
    </v-card-title>
    <v-form ref="form">
      <v-card-text>
        <v-row dense justify="center" align="center" >
          <v-col cols = "6" class = "myColsInt">
            <v-col :cols="esMobile?6:4" class = "myCols">
              <v-subheader class="pa-0">Número C.T.G.</v-subheader>
            </v-col>
            <v-col cols="6" class = "myCols">
               <v-text-field outlined solo dense v-model="form.nroctg"
              class="smallInput" :rules="ruleObligatorio"
              @keypress="onlyNumbers($event)"></v-text-field>
            </v-col>
          </v-col>
          <v-col cols = "6" class = "myColsInt">
            <v-col cols="6" class = "myCols">
              <v-subheader :class="esMobile?'pa-0':'pr-5 ml-auto'"
              >Número RENSPA</v-subheader>
            </v-col>
            <v-col cols="6" class = "myCols">
               <v-text-field outlined solo dense v-model="form.nrorenspa"
              class="smallInput" :rules="ruleObligatorio"
              @keypress="onlyNumbers($event)"></v-text-field>
            </v-col>
          </v-col>
          <v-col cols="12" class="py-0"><hr>
            <b>1. Datos de intervinientes en el traslado de granos.</b>
          </v-col>
          <v-col :lg = "esMobile?12:8" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Titular</v-subheader>
            </v-col>
            <v-col :cols="esMobile?8:7" class = "myCols">
               <v-text-field outlined solo dense v-model="form.titular"
              class="smallInput" disabled></v-text-field>
            </v-col>
          </v-col>
          <v-col lg = "4" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">CUIT Nro</v-subheader>
            </v-col>
            <v-col cols="8" class = "myCols">
               <v-text-field outlined solo dense v-model="form.titularcuit"
              class="smallInput" disabled></v-text-field>
            </v-col>
          </v-col>
          <!-- Intermediario -->
          <v-col :lg = "esMobile?12:8" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Intermediario</v-subheader>
            </v-col>
            <v-col :cols="esMobile?8:7" class = "myCols">
              <v-select outlined solo dense v-model="form.interm"
              class="smallInput" :items="comboIntermediario"
              item-text="nombre" item-value="nombre" v-if="!otroInterm"
              @change="change(form.interm, 'interm', comboIntermediario)"
              ></v-select>
              <v-text-field outlined solo dense v-model="form.intermNew"
              class="smallInput mr-1" v-if="otroInterm"></v-text-field>
              <v-btn icon v-if="otroInterm" small
              @click="otroInterm = false; form.interm = 0">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-col>
          <v-col lg = "4" class = "myColsInt">
            <v-col lg="4" class = "myCols">
              <v-subheader class="pa-0">CUIT Nro</v-subheader>
            </v-col>
            <v-col cols="8" class = "myCols">
              <v-text-field outlined solo dense v-model="form.intermcuit"
              class="smallInput" :rules="ruleCUIT"></v-text-field>
            </v-col>
          </v-col>
          <!-- Remitente Comercial -->
          <v-col :lg = "esMobile?12:8" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Remitente Comercial</v-subheader>
            </v-col>
            <v-col :cols="esMobile?8:7" class = "myCols">
              <v-select outlined solo dense v-model="form.remcom"
              class="smallInput" :items="comboRemitente"
              item-text="nombre" item-value="nombre" v-if="!otroRemcom"
              @change="change(form.remcom, 'remcom', comboRemitente)"></v-select>
              <v-text-field outlined solo dense v-model="form.remcomNew"
              class="smallInput mr-1" v-if="otroRemcom"></v-text-field>
              <v-btn icon v-if="otroRemcom" small
              @click="otroRemcom = false; form.remcom = 0">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-col>
          <v-col lg = "4" class = "myColsInt">
            <v-col lg="4" class = "myCols">
              <v-subheader class="pa-0">CUIT Nro</v-subheader>
            </v-col>
            <v-col cols="8" class = "myCols">
              <v-text-field outlined solo dense v-model="form.remcomcuit"
              class="smallInput" :rules="ruleCUIT"></v-text-field>
            </v-col>
          </v-col>
          <!-- Corredor Comprador -->
          <v-col :lg = "esMobile?12:8" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Corredor Comprador</v-subheader>
            </v-col>
            <v-col :cols="esMobile?8:7" class = "myCols">
              <v-select outlined solo dense v-model="form.corrcom"
              class="smallInput" :items="comboCorrComp"
              item-text="nombre" item-value="nombre" v-if="!otroCorrcom"
              @change="change(form.corrcom, 'corrcom', comboCorrComp)"></v-select>
              <v-text-field outlined solo dense v-model="form.corrcomNew"
              class="smallInput mr-1" v-if="otroCorrcom"></v-text-field>
              <v-btn icon v-if="otroCorrcom" small
              @click="otroCorrcom = false; form.corrcom = 0">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-col>
          <v-col lg = "4" class = "myColsInt">
            <v-col lg="4" class = "myCols">
              <v-subheader class="pa-0">CUIT Nro</v-subheader>
            </v-col>
            <v-col cols="8" class = "myCols">
              <v-text-field outlined solo dense v-model="form.corrcomcuit"
              class="smallInput" :rules="ruleCUIT"></v-text-field>
            </v-col>
          </v-col>
          <!-- Mercado a término -->
          <v-col :lg = "esMobile?12:8" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Mercado a término</v-subheader>
            </v-col>
            <v-col :cols="esMobile?8:7" class = "myCols">
              <v-select outlined solo dense v-model="form.merter"
              class="smallInput" :items="comboMercado"
              item-text="nombre" item-value="nombre" v-if="!otroMerter"
              @change="change(form.merter, 'merter', comboMercado)"></v-select>
              <v-text-field outlined solo dense v-model="form.merterNew"
              class="smallInput mr-1" v-if="otroMerter"></v-text-field>
              <v-btn icon v-if="otroMerter" small
              @click="otroMerter = false; form.merter = 0">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-col>
          <v-col lg = "4" class = "myColsInt">
            <v-col lg="4" class = "myCols">
              <v-subheader class="pa-0">CUIT Nro</v-subheader>
            </v-col>
            <v-col cols="8" class = "myCols">
              <v-text-field outlined solo dense v-model="form.mertercuit"
              class="smallInput" :rules="ruleCUIT"></v-text-field>
            </v-col>
          </v-col>
          <!-- Corredor vendedor -->
          <v-col :lg = "esMobile?12:8" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Corredor vendedor</v-subheader>
            </v-col>
            <v-col :cols="esMobile?8:7" class = "myCols">
              <v-select outlined solo dense v-model="form.corrvend"
              class="smallInput" :items="comboCorrVend"
              item-text="nombre" item-value="nombre" v-if="!otroCorrvend"
              @change="change(form.corrvend, 'corrvend', comboCorrVend)"></v-select>
              <v-text-field outlined solo dense v-model="form.corrvendNew"
              class="smallInput mr-1" v-if="otroCorrvend"></v-text-field>
              <v-btn icon v-if="otroCorrvend" small
              @click="otroCorrvend = false; form.corrvend = 0">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-col>
          <v-col lg = "4" class = "myColsInt">
            <v-col lg="4" class = "myCols">
              <v-subheader class="pa-0">CUIT Nro</v-subheader>
            </v-col>
            <v-col cols="8" class = "myCols">
              <v-text-field outlined solo dense v-model="form.corrvendcuit"
              class="smallInput" :rules="ruleCUIT"></v-text-field>
            </v-col>
          </v-col>
          <!-- Entregador -->
          <v-col :lg = "esMobile?12:8" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Entregador</v-subheader>
            </v-col>
            <v-col :cols="esMobile?8:7" class = "myCols">
              <v-text-field outlined solo dense value="WEIMAN PABLO ARIEL"
              class="smallInput" disabled></v-text-field>
            </v-col>
          </v-col>
          <v-col lg = "4" class = "myColsInt">
            <v-col lg="4" class = "myCols">
              <v-subheader class="pa-0">CUIT Nro</v-subheader>
            </v-col>
            <v-col cols="8" class = "myCols">
              <v-text-field outlined solo dense value="20-23574156-4"
              class="smallInput" disabled></v-text-field>
            </v-col>
          </v-col>
          <!-- Destinatario -->
          <v-col :lg = "esMobile?12:8" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Destinatario</v-subheader>
            </v-col>
            <v-col :cols="esMobile?8:7" class = "myCols">
              <v-select outlined solo dense v-model="form.dest"
              class="smallInput" :items="comboDestinatario"
              item-text="nombre" item-value="nombre" v-if="!otroDest"
              @change="change(form.dest, 'dest', comboDestinatario)"></v-select>
              <v-text-field outlined solo dense v-model="form.destNew"
              class="smallInput mr-1" v-if="otroDest"></v-text-field>
              <v-btn icon v-if="otroDest" small
              @click="otroDest = false; form.dest = 0">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-col>
          <v-col lg = "4" class = "myColsInt">
            <v-col lg="4" class = "myCols">
              <v-subheader class="pa-0">CUIT Nro</v-subheader>
            </v-col>
            <v-col cols="8" class = "myCols">
              <v-text-field outlined solo dense v-model="form.destcuit"
              class="smallInput" :rules="ruleCUIT"></v-text-field>
            </v-col>
          </v-col>
          <!-- Destino -->
          <v-col :lg = "esMobile?12:8" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Destino</v-subheader>
            </v-col>
            <v-col :cols="esMobile?8:7" class = "myCols">
              <v-select outlined solo dense v-model="form.destino"
              class="smallInput" :items="comboDestino"
              item-text="nombre" item-value="nombre" v-if="!otroDestino"
              @change="change(form.destino, 'destino', comboDestino)"></v-select>
              <v-text-field outlined solo dense v-model="form.destinoNew"
              class="smallInput mr-1" v-if="otroDestino"></v-text-field>
              <v-btn icon v-if="otroDestino" small
              @click="otroDestino = false; form.destino = 0">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-col>
          <v-col lg = "4" class = "myColsInt">
            <v-col lg="4" class = "myCols">
              <v-subheader class="pa-0">CUIT Nro</v-subheader>
            </v-col>
            <v-col cols="8" class = "myCols">
              <v-text-field outlined solo dense v-model="form.destinocuit"
              class="smallInput" :rules="ruleCUIT"></v-text-field>
            </v-col>
          </v-col>
          <!-- Intermedio del flete -->
          <v-col :lg = "esMobile?12:8" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Intermediario del flete</v-subheader>
            </v-col>
            <v-col :cols="esMobile?8:7" class = "myCols">
              <v-text-field outlined solo dense v-model="form.intflete"
              class="smallInput"></v-text-field>
            </v-col>
          </v-col>
          <v-col lg = "4" class = "myColsInt">
            <v-col lg="4" class = "myCols">
              <v-subheader class="pa-0">CUIT Nro</v-subheader>
            </v-col>
            <v-col cols="8" class = "myCols">
              <v-text-field outlined solo dense v-model="form.intfletecuit"
              class="smallInput" :rules="ruleCUIT"></v-text-field>
            </v-col>
          </v-col>
          <!-- Transportista -->
          <v-col :lg = "esMobile?12:8" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Transportista</v-subheader>
            </v-col>
            <v-col :cols="esMobile?8:7" class = "myCols">
              <v-select outlined solo dense v-model="form.transp"
              class="smallInput" :items="comboTransportista"
              item-text="nombre" item-value="nombre" v-if="!otroTransp"
              @change="change(form.transp, 'transp', comboTransportista)"
              ></v-select>
              <v-text-field outlined solo dense v-model="form.transpNew"
              class="smallInput mr-1" v-if="otroTransp"></v-text-field>
              <v-btn icon v-if="otroTransp" small
              @click="otroTransp = false; form.transp = 0;">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-col>
          <v-col lg = "4" class = "myColsInt">
            <v-col lg="4" class = "myCols">
              <v-subheader class="pa-0">CUIT Nro</v-subheader>
            </v-col>
            <v-col cols="8" class = "myCols">
              <v-text-field outlined solo dense v-model="form.transpcuit"
              class="smallInput" :rules="ruleCUIT"></v-text-field>
            </v-col>
          </v-col>
          <!-- Chofer -->
          <v-col :lg = "esMobile?12:8" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Chofer</v-subheader>
            </v-col>
            <v-col :cols="esMobile?8:7" class = "myCols">
              <v-select outlined solo dense v-model="form.chofer"
              class="smallInput" :items="comboChofer"
              item-text="nombre" item-value="nombre" v-if="!otroChofer"
              @change="change(form.chofer, 'chofer', comboChofer)"></v-select>
              <v-text-field outlined solo dense v-model="form.choferNew"
              class="smallInput mr-1" v-if="otroChofer"></v-text-field>
              <v-btn icon v-if="otroChofer" small
                @click="otroChofer = false; form.chofer=0">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-col>
          <v-col lg = "4" class = "myColsInt">
            <v-col lg="4" class = "myCols">
              <v-subheader class="pa-0">CUIT Nro</v-subheader>
            </v-col>
            <v-col cols="8" class = "myCols">
              <v-text-field outlined solo dense v-model="form.chofercuit"
              class="smallInput" :rules="ruleCUIT"></v-text-field>
            </v-col>
          </v-col>
          <v-col cols="12"><hr>
            <b>2. Datos de los granos / especies transportados</b>
          </v-col>
          <v-col cols = "6" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Cosecha</v-subheader>
            </v-col>
            <v-col :cols="esMobile?8:7" class = "myCols">
              <v-select outlined solo dense v-model="form.cosecha"
              class="smallInput" :rules="ruleObligatorio" :items="comboCosecha"
              item-text="nombre" item-value="nombre"></v-select>
            </v-col>
          </v-col>
          <v-col cols = "6" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Contrato</v-subheader>
            </v-col>
            <v-col cols="8" class = "myCols">
              <v-text-field outlined solo dense v-model="form.contrato"
              class="smallInput"></v-text-field>
            </v-col>
          </v-col>
          <v-col cols = "6" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Grano/Especie</v-subheader>
            </v-col>
            <v-col :cols="esMobile?8:7" class = "myCols">
              <v-select outlined solo dense v-model="form.granoesp"
              class="smallInput" :rules="ruleObligatorio" :items="comboGranos"
              item-text="nombre" item-value="nombre"></v-select>
            </v-col>
          </v-col>
          <v-col cols = "6" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Tipo</v-subheader>
            </v-col>
            <v-col cols="8" class = "myCols">
              <v-text-field outlined solo dense v-model="form.tipo"
              class="smallInput" :rules="ruleObligatorio"></v-text-field>
            </v-col>
          </v-col>
          <v-col cols = "6" class = "myColsInt">
            <v-col :cols="esMobile?8:6" class = "myCols">
              <v-subheader class="pa-0">Carga pesada en destino</v-subheader>
            </v-col>
            <v-col cols="4" class = "myCols">
              <v-checkbox dense v-model="form.cargapesada"
              class="smallInput"></v-checkbox>
            </v-col>
          </v-col>
          <v-col cols = "6" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Kgs. Estimados</v-subheader>
            </v-col>
            <v-col cols="8" class = "myCols">
              <v-text-field outlined solo dense v-model="form.kgestimados"
              class="smallInput" @keypress="onlyNumbers($event)"
              :disabled="!form.cargapesada"></v-text-field>
            </v-col>
          </v-col>
          <v-col cols = "6" :class = "esMobile?'':'myColsInt'">
            <v-radio-group column class="smallInput" v-model="form.chkcalidad">
              <v-radio dense class="smallInput" label="Declaración de Calidad">
              </v-radio>
              <v-radio dense class="smallInput" label="Conforme"></v-radio>
              <v-radio dense class="smallInput" label="Condicional"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols = "6" :class="esMobile?'myColsInt': ''">
            <v-row dense class="py-0">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Peso Bruto (Kgrs)</v-subheader>
              </v-col>
              <v-col cols="8" class = "myCols">
                <v-text-field outlined solo dense v-model="form.pesobruto"
                class="smallInput mr-1" @change="cambioPesos()"
                @keypress="onlyNumbers($event)" :disabled="form.cargapesada"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense class="py-0">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Peso Tara (Kgrs)</v-subheader>
              </v-col>
              <v-col cols="8" class = "myCols">
                <v-text-field outlined solo dense v-model="form.pesotara"
                class="smallInput mr-1" @change="cambioPesos()"
                @keypress="onlyNumbers($event)" :disabled="form.cargapesada"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense class="py-0">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Peso Neto (Kgrs)</v-subheader>
              </v-col>
              <v-col cols="8" class = "myCols">
                <v-text-field outlined solo dense v-model="form.pesoneto"
                class="smallInput mr-1" @keypress="onlyNumbers($event)"
                :disabled="form.cargapesada"></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class = "myColsInt">
           <v-col :cols="esMobile?12:2" class = "myCols">
              <v-subheader class="pa-0">Observaciones</v-subheader>
            </v-col>
            <v-col :cols="esMobile?12:10" class = "myCols">
              <v-text-field outlined solo dense v-model="form.obs"
              class="smallInput"></v-text-field>
            </v-col>
          </v-col>
          <v-col cols="12" class="text-center mt-8">
            <b>PROCEDENCIA DE LA MERCADERIA</b>
          </v-col>
          <v-col cols="12" class = "myColsInt">
           <v-col :cols="esMobile?4:2" class = "myCols">
              <v-subheader class="pa-0">Dirección</v-subheader>
            </v-col>
            <v-col :cols="esMobile?8:10" class = "myCols">
              <v-text-field outlined solo dense v-model="form.procdir"
              class="smallInput" :rules="ruleObligatorio"></v-text-field>
            </v-col>
          </v-col>
          <v-col cols = "6" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Localidad</v-subheader>
            </v-col>
            <v-col :cols="esMobile?8:7" class = "myCols">
              <v-text-field outlined solo dense v-model="form.procloc"
              class="smallInput" :rules="ruleObligatorio"></v-text-field>
            </v-col>
          </v-col>
          <v-col cols = "6" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Provincia</v-subheader>
            </v-col>
            <v-col cols="8" class = "myCols">
              <v-text-field outlined solo dense v-model="form.procprov"
              class="smallInput" :rules="ruleObligatorio"></v-text-field>
            </v-col>
          </v-col>
          <v-col cols="12"><hr>
            <b>3. Lugar de destino de los granos</b>
          </v-col>
          <v-col cols="12" class = "myColsInt">
           <v-col :cols="esMobile?4:2" class = "myCols">
              <v-subheader class="pa-0">Dirección</v-subheader>
            </v-col>
            <v-col :cols="esMobile?8:10" class = "myCols">
              <v-text-field outlined solo dense v-model="form.destdir"
              class="smallInput" :rules="ruleObligatorio"></v-text-field>
            </v-col>
          </v-col>
          <v-col cols = "6" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Localidad</v-subheader>
            </v-col>
            <v-col :cols="esMobile?8:7" class = "myCols">
              <v-text-field outlined solo dense v-model="form.destloc"
              class="smallInput" :rules="ruleObligatorio"></v-text-field>
            </v-col>
          </v-col>
          <v-col cols = "6" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Provincia</v-subheader>
            </v-col>
            <v-col cols="8" class = "myCols">
              <v-text-field outlined solo dense v-model="form.destprov"
              class="smallInput" :rules="ruleObligatorio"></v-text-field>
            </v-col>
          </v-col>
          <v-col cols="12"><hr>
            <b>3. Datos del transporte</b>
          </v-col>
          <v-col cols="12" class = "myColsInt">
           <v-col :cols="esMobile?4:2" class = "myCols">
              <v-subheader class="pa-0">Pagador flete</v-subheader>
            </v-col>
            <v-col :cols="esMobile?8:10" class = "myCols">
              <v-text-field outlined solo dense v-model="form.pagflete"
              class="smallInput"></v-text-field>
            </v-col>
          </v-col>
          <v-col cols = "6" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Camión</v-subheader>
            </v-col>
            <v-col :cols="esMobile?8:7" class = "myCols">
              <v-text-field outlined solo dense v-model="form.camion"
              class="smallInput" :rules="ruleObligatorio"></v-text-field>
            </v-col>
          </v-col>
          <v-col cols = "6" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Tarifa</v-subheader>
            </v-col>
            <v-col cols="8" class = "myCols">
              <v-text-field outlined solo dense v-model="form.tarifa"
              class="smallInput"></v-text-field>
            </v-col>
          </v-col>
          <v-col cols = "6" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Acoplado</v-subheader>
            </v-col>
            <v-col :cols="esMobile?8:7" class = "myCols">
              <v-text-field outlined solo dense v-model="form.acoplado"
              class="smallInput" :rules="ruleObligatorio"></v-text-field>
            </v-col>
          </v-col>
          <v-col cols = "6" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Tarifa de referencia</v-subheader>
            </v-col>
            <v-col cols="8" class = "myCols">
              <v-text-field outlined solo dense v-model="form.tarifaref"
              class="smallInput"></v-text-field>
            </v-col>
          </v-col>
          <v-col cols = "6" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Km. a recorrer</v-subheader>
            </v-col>
            <v-col :cols="esMobile?8:7" class = "myCols">
              <v-text-field outlined solo dense v-model="form.kmrecorrer"
              class="smallInput" :rules="ruleObligatorio"></v-text-field>
            </v-col>
          </v-col>
          <v-col cols="6"></v-col>
          <v-col cols="12"><hr>
            <b>Declaración jurada</b>
          </v-col>
          <v-col cols = "6" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">Aclaración</v-subheader>
            </v-col>
            <v-col :cols="esMobile?8:7" class = "myCols">
              <v-text-field outlined solo dense v-model="form.aclarac"
              class="smallInput" :rules="ruleObligatorio"></v-text-field>
            </v-col>
          </v-col>
          <v-col cols = "6" class = "myColsInt">
            <v-col cols="4" class = "myCols">
              <v-subheader class="pa-0">D.N.I.</v-subheader>
            </v-col>
            <v-col cols="8" class = "myCols">
              <v-text-field outlined solo dense v-model="form.dni"
              class="smallInput" :rules="ruleObligatorio"></v-text-field>
            </v-col>
          </v-col>
          <v-col cols="12"><hr>
            <b>Seleccione su carta de porte en BLANCO</b>
          </v-col>
          <v-col cols = "6" class = "myColsInt">
            <v-col :cols="esMobile?4:3" class = "myCols">
              <v-subheader class="pa-0">Archivo PDF</v-subheader>
            </v-col>
            <v-col cols="8" class = "myCols">
              <v-file-input outlined solo dense v-model="file" color="black"
              class="smallInput" prepend-icon="mdi-file-pdf" accept=".pdf"
              @change="changeFile" @click:clear="deleteFile"></v-file-input>
            </v-col>
          </v-col>
          <v-col cols = "6" class = "myColsInt">
            <v-col :cols="esMobile?6:4" class = "myCols">
              <v-subheader class="pa-0">Seleccione rango</v-subheader>
            </v-col>
            <v-col :cols="esMobile?6:8" class = "myCols">
              <v-select outlined solo dense v-model="rango" id="rango"
              class="smallInput" :items="rangoPags"></v-select>
            </v-col>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn small rounded :class="esMobile?'':'px-5'" color="#4b4b4b" dark
        @click="vaciarForm()">
        Vaciar Form.</v-btn>
        <v-spacer></v-spacer>
        <v-btn small rounded :class="esMobile?'':'px-5'" color="#4b4b4b" dark
        @click="completarCarta()">
        Completar carta</v-btn>
      </v-card-actions>
    </v-form>
    </v-card>
    <MLoading v-if="loading"/>
  </v-container>
</template>
<style>
  .smallInput fieldset {
    border: 2px solid #ffbb00;
  }

  .smallInput.v-input--is-disabled fieldset {
    background-color: #f4f4f4;
  }

  .smallInput.v-input--is-focused fieldset {
   border: 2px solid #ffbb00;
  }
  .form-control-Int {
    height: 34px;
    padding: 6px 12px;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ffbb00;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    padding: 3px 3px;
    height: 21px;
    margin: 0 3px;
    margin-right: 5px;
    display: block;
    font-size: small;
    width: 100%
  }
  .smallInput.v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
    margin: 0;
    max-height: 28px;
    min-height: 28px !important;
    display: flex!important;
    align-items: center!important
  }
  .smallInput.v-text-field.v-text-field--enclosed .v-text-field__details {
    margin: 0px;
  }

  .smallInput.v-input--selection-controls {
    margin: 0px;
  }
  .myColsInt {
    display: block ruby;
    padding-top: 0px !important; padding-bottom: 0px !important;
  }
  .v-subheader {
    height: 40px;
  }
  .cardInteract {
    background: rgba(255,255,255,0.7) !important;
    overflow-y: scroll;
    max-height: 700px;
    transform: scale(0.95);
  }
  .smallInput.v-input--is-disabled {
    cursor: not-allowed !important;
    pointer-events: auto !important;
  }
  @media (max-width: 900px) {
   .myColsInt {
      display: contents;
      padding-top: 0px !important; padding-bottom: 0px !important;
   }
   .v-card__title {
    text-align: justify;
    font-size: 16px;
   }
  }
</style>
<script>
import MLoading from '@/components/Cliente/MLoading.vue';
import axios from 'axios';
import url from '@/var.js';

export default {
  name: 'Camiones',
  title: 'Pablo Weiman - Cartas de Porte',
  components: {
    MLoading,
  },
  data() {
    return {
      form: {
        nroctg: '',
        nrorenspa: '',
        titular: '',
        titularcuit: '',
        interm: 0,
        intermNew: '',
        intermcuit: '',
        remcom: 0,
        remcomNew: '',
        remcomcuit: '',
        corrcom: 0,
        corrcomNew: '',
        corrcomcuit: '',
        merter: 0,
        merterNew: '',
        mertercuit: '',
        corrvend: 0,
        corrvendNew: '',
        corrvendcuit: '',
        entreg: 'WEIMAN PABLO ARIEL',
        entregcuit: '20-23574156-4',
        dest: 0,
        destNew: '',
        destcuit: '',
        destino: 0,
        destinoNew: '',
        destinocuit: '',
        intflete: '',
        intfletecuit: '',
        transp: 0,
        transpNew: '',
        transpcuit: '',
        chofer: 0,
        choferNew: '',
        chofercuit: '',
        cosecha: 0,
        contrato: '',
        granoesp: 0,
        tipo: '',
        cargapesada: false,
        kgestimados: '',
        chkcalidad: 0,
        pesobruto: '',
        pesotara: '',
        pesoneto: '',
        obs: '',
        procdir: '',
        procloc: '',
        procprov: '',
        destdir: '',
        destloc: '',
        destprov: '',
        pagflete: '',
        camion: '',
        tarifa: '',
        acoplado: '',
        tarifaref: '',
        kmrecorrer: '',
        aclarac: '',
        dni: '',
      },
      file: undefined,
      rango: 1,
      comboIntermediario: [],
      comboRemitente: [],
      comboCorrComp: [],
      comboMercado: [],
      comboCorrVend: [],
      comboDestinatario: [],
      comboDestino: [],
      comboTransportista: [],
      comboChofer: [],
      comboCosecha: [],
      comboGranos: [],
      rangoPags: [
        { text: '1 - 4', value: 1 },
        { text: '5 - 8', value: 2 },
        { text: '9 - 12', value: 3 },
        { text: '13 - 16', value: 4 },
        { text: '17 - 20', value: 5 },
        { text: '21 -24', value: 6 },
        { text: '25 - 28', value: 7 },
        { text: '29 - 32', value: 8 },
        { text: '33 - 36', value: 9 },
        { text: '37 - 40', value: 10 },
        { text: '41 - 44', value: 11 },
        { text: '45 - 48', value: 12 },
      ],
      otroInterm: false,
      otroRemcom: false,
      otroCorrcom: false,
      otroMerter: false,
      otroCorrvend: false,
      otroDest: false,
      otroDestino: false,
      otroTransp: false,
      otroChofer: false,
      pathFile: '',
      ruleObligatorio: [
        (v) => !!v || 'Este campo es obligatorio.',
      ],
      ruleCUIT: [
        (v) => (!!v && this.validarCuit(v)) || 'La CUIT ingresada no es válida.',
      ],
      esMobile: this.isMobile(),
      loading: true,
    };
  },
  methods: {
    cargarCombos() {
      this.loading = true;
      const path = `${url}/combosInteractiva`;
      axios.post(path, {
        id: this.$session.get('id'),
        token: this.$session.get('token'),
      })
        .then((response) => {
          this.form.titular = response.data.titular.nombre;
          this.form.titularcuit = response.data.titular.nrodoc;
          this.comboIntermediario = response.data.intermediario;
          this.comboIntermediario.unshift({ codigo: 1, nombre: 'Otro...' });
          this.comboRemitente = response.data.remitente;
          this.comboRemitente.unshift({ codigo: 1, nombre: 'Otro...' });
          this.comboCorrComp = response.data.corredorComprador;
          this.comboCorrComp.unshift({ codigo: 1, nombre: 'Otro...' });
          this.comboMercado = response.data.mercado;
          this.comboMercado.unshift({ codigo: 1, nombre: 'Otro...' });
          this.comboCorrVend = response.data.corredorVendedor;
          this.comboCorrVend.unshift({ codigo: 1, nombre: 'Otro...' });
          this.comboDestinatario = response.data.destinatario;
          this.comboDestinatario.unshift({ codigo: 1, nombre: 'Otro...' });
          this.comboDestino = response.data.destino;
          this.comboDestino.unshift({ codigo: 1, nombre: 'Otro...' });
          this.comboTransportista = response.data.transportista;
          this.comboTransportista.unshift({ codigo: 1, nombre: 'Otro...' });
          this.comboChofer = response.data.chofer;
          this.comboChofer.unshift({ codigo: 1, nombre: 'Otro...' });
          this.comboCosecha = response.data.cosecha;
          this.comboGranos = response.data.granos;
          this.loading = false;
        });
    },
    changeFile() {
      if (this.file !== undefined) {
        if (this.file.type === 'application/pdf') {
          const formData = new FormData();
          formData.append('file', this.file);
          formData.append('id', this.$session.get('id'));
          formData.append('token', this.$session.get('token'));
          const path = `${url}/uploadFile`;
          axios.post(path, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
            .then((response) => {
              if (response.data.success) {
                this.pathFile = response.data.file;
              } else {
                this.pathFile = '';
                this.file = undefined;
                this.$alert(response.data.msg, 'Atención', 'warning');
              }
            });
        } else {
          this.pathFile = '';
          this.file = undefined;
          this.$alert('El archivo debe ser pdf', 'Atención', 'warning');
        }
      }
    },
    deleteFile() {
      if (this.file !== undefined) {
        const path = `${url}/deleteFile`;
        axios.post(path, {
          id: this.$session.get('id'),
          token: this.$session.get('token'),
          path: this.pathFile,
        })
          .then(() => {
            this.pathFile = '';
          });
      }
    },
    change(selected, name, list) {
      const i = list.findIndex((item) => item.nombre === selected);
      if (list[i].codigo === 1) {
        this.insertNew(name);
      } else {
        this.updateCUIT(list[i].nrodoc, name);
      }
    },
    updateCUIT(cuit, name) {
      switch (name) {
        case 'interm':
          this.form.intermcuit = cuit; break;
        case 'remcom':
          this.form.remcomcuit = cuit; break;
        case 'corrcom':
          this.form.corrcomcuit = cuit; break;
        case 'merter':
          this.form.mertercuit = cuit; break;
        case 'corrvend':
          this.form.corrvendcuit = cuit; break;
        case 'dest':
          this.form.destcuit = cuit; break;
        case 'destino':
          this.form.destinocuit = cuit; break;
        case 'transp':
          this.form.transpcuit = cuit; break;
        case 'chofer':
          this.form.chofercuit = cuit; break;
        default:
      }
    },
    insertNew(name) {
      switch (name) {
        case 'interm':
          this.otroInterm = true; break;
        case 'remcom':
          this.otroRemcom = true; break;
        case 'corrcom':
          this.otroCorrcom = true; break;
        case 'merter':
          this.otroMerter = true; break;
        case 'corrvend':
          this.otroCorrvend = true; break;
        case 'dest':
          this.otroDest = true; break;
        case 'destino':
          this.otroDestino = true; break;
        case 'transp':
          this.otroTransp = true; break;
        case 'chofer':
          this.otroChofer = true; break;
        default:
      }
    },
    completarCarta() {
      if (this.$refs.form.validate()) {
        if (this.form.kgestimados === '' && this.form.pesoneto === '') {
          this.$alert('Los kgs Estimados o peso neto deben estar completos', 'Atención', 'warning');
        } else if (this.file === undefined) {
          this.$alert('Debe adjuntar la carta de porte a completar', 'Atención', 'warning');
        } else {
          this.sendDatos();
        }
      } else {
        this.$alert('Por favor, complete los campos obligatorios', 'Atención', 'warning');
      }
    },
    sendDatos() {
      const path = `${url}/completarCarta`;
      axios.get(path, {
        params: {
          id: this.$session.get('id'),
          token: this.$session.get('token'),
          path: this.pathFile,
          form: this.form,
          pags: this.rango,
        },
        responseType: 'arraybuffer',
      })
        .then((response) => {
          if (response.data.byteLength > 0) {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url2 = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url2;
            const fileName = response.headers['content-disposition'].split('=')[1];
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
          } else {
            this.$alert('El rango seleccionado no existe en el archivo adjunto', 'Atención', 'warning');
          }
        });
    },
    validarCuit(cuit) {
      let cuitValido = true;
      let i;
      let r = 0;
      if (cuit === '') {
        cuitValido = false;
      } else {
        const ref = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
        const aux = cuit.replace(new RegExp('-', 'g'), '');
        const soloNro = cuit.replace(/[^0-9]/g, '');
        if (soloNro !== aux || aux.length !== 11) {
          cuitValido = false;
          return false;
        }
        for (i = 0; i < 10; i += 1) {
          r += parseInt(aux[i], 10) * ref[i];
        }
        r = 11 - (r % 11);
        if (r >= 10) {
          r = 0;
        }
        if (r !== parseInt(aux[10], 10)) {
          cuitValido = false;
        }
      }
      return cuitValido;
    },
    cambioPesos() {
      if (this.form.pesotara !== '' && this.form.pesobruto !== '') {
        const aux = this.form.pesobruto - this.form.pesotara;
        this.form.pesoneto = Math.round(aux * 100) / 100;
      }
    },
    onlyNumbers(event) {
      const keyCode = (event.keyCode ? event.keyCode : event.which);
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      } else {
        return true;
      }
      return false;
    },
    vaciarForm() {
      this.$refs.form.reset();
      this.$nextTick(() => {
        this.form.chkcalidad = 0;
        this.rango = 1;
      });
    },
    isMobile() {
      if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || (window.innerWidth < 800)) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.cargarCombos();
  },
};
</script>
