import Vue from 'vue';
import Router from 'vue-router';
import Login from './components/Cliente/Login.vue';
import Inicio from './components/Cliente/Inicio.vue';
import Interactiva from './components/Cliente/Interactiva.vue';
import Principal from './components/General/Principal.vue';
import Institucional from './components/General/Institucional.vue';
import Servicios from './components/General/Servicios.vue';
import Contacto from './components/General/Contacto.vue';

Vue.use(Router);

const vueRouter = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Principal',
      component: Principal,
    },
    {
      path: '/institucional',
      name: 'Institucional',
      component: Institucional,
    },
    {
      path: '/servicios/*',
      name: 'Servicios',
      component: Servicios,
    },
    {
      path: '/servicios',
      name: 'Servicios',
      component: Servicios,
    },
    {
      path: '/contacto',
      name: 'Contacto',
      component: Contacto,
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        title: 'Pablo Weiman - Ingreso',
      },
    },
    {
      path: '/inicio',
      name: 'Inicio',
      component: Inicio,
      meta: {
        title: 'Pablo Weiman - Cartas de Porte',
      },
    },
    {
      path: '/interactiva',
      name: 'interactiva',
      component: Interactiva,
    },
  ],
});

vueRouter.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default vueRouter;
