import Vue from 'vue';
import VueSession from 'vue-session';
import VueSimpleAlert from 'vue-simple-alert';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.use(VueSession);
Vue.use(VueSimpleAlert);
Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
