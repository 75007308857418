<template>
  <div>
    <v-toolbar color="#000000" height="100" class="toolbarInicio">
      <v-col cols="1"></v-col>
      <v-col :cols="(isMobile()?9:3)">
        <span @click="irAInicio()" :disabled="!registrado">
        <v-img src = "@/assets/Weiman_logotipo.svg" contain max-height="100">
        </v-img></span>
      </v-col>
      <v-col :cols="(isMobile()?2:8)">
        <div v-if = "!isMobile()">
          <v-row>
            <v-spacer></v-spacer>
            <v-toolbar-title style="color:#ffbb00">{{ nombre }}</v-toolbar-title>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn text color="white" @click = "modalListados = true"
            v-if="registrado">Listados</v-btn>
            <!--<v-btn text color="white" @click = "modalBLote = true"
            v-if="registrado">Boletines</v-btn>-->
            <v-btn text color="white" @click = "modalClave = true"
            v-if="registrado">Cambiar mi clave</v-btn>
            <v-btn text color="white" @click = "modalSalir = true">Salir</v-btn>
          </v-row>
        </div>
        <div v-else class = "text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-btn
                color="primary"
                dark
                icon
                v-on="on"
              >
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click= "modalListados = true" v-if="registrado">
                <v-list-item-title>Listados</v-list-item-title>
              </v-list-item>
              <!--<v-list-item @click= "modalBLote = true" v-if="registrado">
                <v-list-item-title>Boletines</v-list-item-title>
              </v-list-item>-->
              <v-list-item @click = "modalClave = true" v-if="registrado">
                <v-list-item-title>Cambiar clave</v-list-item-title>
              </v-list-item>
              <v-list-item @click = "salir">
                <v-list-item-title>Salir</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
      <v-col cols="1">
      </v-col>
    </v-toolbar>
    <v-dialog v-model = "modalListados" max-width = "500">
      <v-card class="cardListados">
        <v-toolbar flat>
          <v-toolbar-title>Listados</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click = "modalListados = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="px-12">
          <v-row align = "center">
            <v-col class = "mx-auto" cols = "12">
              <v-select
                v-model = "listSelected"
                :items = "listados"
                item-text = "text"
                item-value = "val"
                @change = "changeListadoSelected"
                width="auto"
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-if = "inputFecha">
            <v-col class="mx-auto" cols="6" sm = "6">
              <v-menu
                v-model="menuListF"
                nudge-left
                transition="scale-transition"
                offset-y dark
                min-width="290px" class="menuPicker"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="fechaDesdeSList"
                    outlined dark
                    filled
                    max-height = "100px"
                    append-icon="event"
                    @blur = "fechadesdeList = parseDate(fechaDesdeSList)"
                    v-on="on"
                    readonly class="textPicker"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaDesdeList"
                  locale ="es" dark
                  no-title class="datePicker"
                  @input = "menuListF= false">
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row v-if = "inputDosFechas">
            <v-col class="mx-auto pb-0" cols="6" sm = "6">
              <v-menu
                v-model="menuList"
                nudge-left dark
                transition="scale-transition"
                offset-y
                min-width="290px" class="menuPicker"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="fechaDesdeSList"
                    outlined
                    filled dark
                    max-height = "100px"
                    append-icon="event"
                    @blur = "fechadesdeList = parseDate(fechaDesdeSList)"
                    v-on="on"
                    readonly class="textPicker"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaDesdeList"
                  locale ="es" dark
                  no-title class="datePicker"
                  @input = "menuList= false">
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="mx-auto pb-0" cols="6" sm = "6">
              <v-menu
                v-model="menu2List"
                transition="scale-transition"
                offset-y dark
                min-width="290px" class="menuPicker"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="fechaHastaSList"
                    outlined
                    filled dark
                    append-icon="event"
                    @blur ="fechahastaList = parseDate(fechaHastaSList)"
                    v-on="on"
                    readonly class="textPicker"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaHastaList"
                  locale ="es" dark
                  value ="dd-mm-YYYY" class="datePicker"
                  no-title>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row class = "d-flex justify-center" v-if = "inputCheckbox">
            <v-checkbox class = "ma-0" off-icon="mdi-radiobox-blank"
            on-icon="mdi-radiobox-marked"
              label = "Poner un grupo por hoja" v-model = "chkListados">
            </v-checkbox>
          </v-row>
          <v-row class = "d-flex justify-center">
            <v-btn class = "mx-2 px-8" :disabled = "disable" v-if = "generarPdf"
              color = "#4b4b4b" @click = "generarListado('pdf')"
              rounded dark>Generar PDF
            </v-btn>
            <v-btn class = "mx-2 px-8" :disabled = "disable" v-if = "generarXls"
              color = "#4b4b4b" @click = "generarListado('xls')"
              rounded dark>Generar XLS
            </v-btn>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn right @click = "modalListados = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model = "modalBLote" max-width = "500">
      <v-card class="cardBLote">
        <v-toolbar flat>
          <v-toolbar-title>Boletines en lote</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click = "modalBLote = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="px-12">
          <v-row>
            <v-col class="mx-auto pb-0" cols="6" sm = "6">
              <v-menu
                v-model="menuDB"
                nudge-left dark
                transition="scale-transition"
                offset-y
                min-width="290px" class="menuPicker"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="fechaDBoletin"
                    outlined
                    filled dark
                    max-height = "100px"
                    append-icon="event"
                    @blur = "fechadBoletinP = parseDate(fechaDBoletin)"
                    v-on="on"
                    readonly class="textPicker"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaDBoletinP"
                  locale ="es" dark
                  no-title class="datePicker"
                  @input = "menuDB = false">
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="mx-auto pb-0" cols="6" sm = "6">
              <v-menu
                v-model="menuHB"
                transition="scale-transition"
                offset-y dark
                min-width="290px" class="menuPicker"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="fechaHBoletin"
                    outlined
                    filled dark
                    append-icon="event"
                    @blur ="fechahBoletinP = parseDate(fechaHBoletin)"
                    v-on="on"
                    readonly class="textPicker"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaHBoletinP"
                  locale ="es" dark
                  value ="dd-mm-YYYY" class="datePicker"
                  @input = "menuHB = false"
                  no-title>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn right @click = "generarBLote">Generar</v-btn>
          <v-btn right @click = "modalBLote = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model = "modalClave" max-width = "400">
      <v-card>
        <v-toolbar flat>
          <v-icon>mdi-account</v-icon>
          <v-toolbar-title class = "pl-1">
            Cambio de contraseña
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click = "modalClave = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-snackbar
            v-model = "snackClave"
            :timeout = "timeout"
            > {{ txtSnackClave }}
            <v-btn
              color="blue"
              text
              @click="snackClave = false"
            > Cerrar</v-btn>
          </v-snackbar>
          <v-text-field
            label = "Nueva contraseña"
            v-model = "nuevaPass"
            outlined
            autofocus
            type = "password"
          ></v-text-field>
          <v-text-field
            label = "Repetir contraseña"
            v-model = "repeatPass"
            outlined
            type = "password"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color = "primary" @click = "cambiarClave">Aceptar</v-btn>
          <v-btn @click = "modalClave = false">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loading" fullscreen>
      <v-container
        fluid fill-height style="background-color: rgba(255, 255, 255, 0.5);">
        <v-layout justify-center align-center>
          <v-progress-circular
            indeterminate
            color="primary">
          </v-progress-circular>
        </v-layout>
      </v-container>
    </v-dialog>
    <v-dialog v-model = "modalSalir" max-width = "400">
      <v-card>
        <v-toolbar flat>
          <v-icon>mdi-lock</v-icon>
          <v-toolbar-title>
            Salida del sistema
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click = "modalSalir = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text><br>
          <p>
          ¿Está seguro que desea salir?</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click = "salir" color = "primary">Aceptar</v-btn>
          <v-btn @click = "modalSalir = false" outlined>Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style>
.toolbarInicio {
  background: rgba(0, 0, 0, 0.6) !important;
}
.v-toolbar__title {
  color: black
}
.toolbarTitle {
  font-size: 1.25 rem;
  line-height: 1.5;
  color: #ffbb00
}
</style>
<script>
import axios from 'axios';
import url from '@/var.js';

export default {
  name: 'Header',
  data() {
    return {
      registrado: (this.$session.get('id') !== 1),
      nombre: '',
      modalListados: false,
      listados: [
        { text: 'Seleccione un listado', val: -1 },
        { text: 'Listado para Clientes y Corredores', val: 0 },
        { text: 'Listado por Cuenta y Grano', val: 1 },
        { text: 'Listado Cronológico', val: 4 },
        { text: 'Listado por Grano', val: 2 },
        { text: 'Planilla General', val: 3 },
      ],
      listSelected: -1,
      inputFecha: false,
      inputCheckbox: false,
      inputDosFechas: false,
      generarPdf: true,
      generarXls: true,
      disable: true,
      menuList: false,
      menu2List: false,
      menuListF: false,
      fechaDesdeList: new Date().toISOString().substr(0, 10),
      fechaHastaList: new Date().toISOString().substr(0, 10),
      fechaDesdeSList: this.formatFecha(new Date().toISOString().substr(0, 10)),
      fechaHastaSList: this.formatFecha(new Date().toISOString().substr(0, 10)),
      fechaList: new Date().toISOString().substr(0, 10),
      fechaSList: this.formatFecha(new Date().toISOString().substr(0, 10)),
      fechaDBoletinP: new Date().toISOString().substr(0, 10),
      fechaHBoletinP: new Date().toISOString().substr(0, 10),
      fechaDBoletin: this.formatFecha(new Date().toISOString().substr(0, 10)),
      fechaHBoletin: this.formatFecha(new Date().toISOString().substr(0, 10)),
      chkListados: false,
      modalClave: false,
      nuevaPass: '',
      repeatPass: '',
      snackClave: false,
      txtSnackClave: '',
      modalSalir: false,
      modalBLote: false,
      loading: false,
      timeout: 6000,
      menuDB: false,
      menuHB: false,
    };
  },
  methods: {
    init() {
      if (this.$session.get('id') == null) {
        this.$router.push('/');
        this.$router.go(0);
      } else if (this.registrado) {
        const path = `${url}/getIniciales`;
        axios.post(path, {
          id: this.$session.get('id'),
          token: this.$session.get('token'),
        })
          .then((response) => {
            this.nombre = response.data.nombre;
            this.granos = response.data.granos;
            this.granos.push({ codigo: -1, nombre: 'Todos' });
          });
      }
    },
    isMobile() {
      return ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || (window.innerWidth < 800));
    },
    salir() {
      const path = `${url}/logout`;
      axios.post(path, {
        id: this.$session.get('id'),
        token: this.$session.get('token'),
      })
        .then(() => {
          this.$session.destroy();
          this.$router.push('/');
        });
    },
    formatFecha(date) {
      if (!date) {
        return null;
      }
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    irAInicio() {
      if (this.registrado) {
        this.$router.push('/inicio');
      }
    },
    changeListadoSelected() {
      switch (this.listSelected) {
        case 0:
          this.inputDosFechas = true;
          this.inputCheckbox = true;
          this.inputFecha = false;
          this.disable = false;
          this.generarXls = true;
          this.generarPdf = true;
          break;
        case 1:
        case 2:
          this.inputDosFechas = true;
          this.inputCheckbox = true;
          this.inputFecha = false;
          this.disable = false;
          this.generarXls = true;
          this.generarPdf = true;
          break;
        case 3:
          this.inputFecha = true;
          this.inputDosFechas = false;
          this.inputCheckbox = false;
          this.disable = false;
          this.generarXls = true;
          this.generarPdf = false;
          break;
        case 4:
          this.inputFecha = false;
          this.inputDosFechas = true;
          this.inputCheckbox = true;
          this.disable = false;
          this.generarXls = true;
          this.generarPdf = true;
          break;
        default:
          this.inputFecha = false;
          this.inputDosFechas = false;
          this.inputCheckbox = false;
          this.disable = true;
          this.generarXls = true;
          this.generarPdf = true;
      }
    },
    parseDate(date) {
      if (!date) {
        return null;
      }
      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    generarListado(tipoArch) {
      this.loading = true;
      const path = `${url}/generarListado`;
      axios.get(path, {
        params: {
          id: this.$session.get('id'),
          token: this.$session.get('token'),
          datos: {
            fDesde: this.fechaDesdeList,
            fHasta: this.fechaHastaList,
            chk: this.chkListados,
            fecha: this.fechaList,
            tipo: this.listSelected,
            tipoArchivo: tipoArch,
            nombre: this.nombre,
          },
        },
        responseType: 'arraybuffer',
      })
        .then((response) => {
          if (response.status === 200) {
            if (response.headers['content-type'] === 'application/json') {
              this.$fire({
                text: 'No hay datos para generar el archivo.',
                title: 'Atención',
                type: 'warning',
              });
            } else if (tipoArch === 'xls') {
              const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
              const url2 = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url2;
              const fileName = response.headers['content-disposition'].split('=')[1];
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
            } else {
              const blob = new Blob([response.data], { type: 'application/pdf' });
              const url2 = window.URL.createObjectURL(blob);
              const link = document.createElement('a');
              link.href = url2;
              const fileName = response.headers['content-disposition'].split('=')[1];
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
            }
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
          this.$fire({
            title: '¡Atención!',
            text: 'Hubo un error al generar el listado, por favor intente nuevamente más tarde.',
            type: 'error',
          });
        });
    },
    cambiarClave() {
      if (this.nuevaPass.trim() === '') {
        this.txtSnackClave = 'La contraseña no puede ser vacía';
        this.snackClave = true;
      } else if (this.nuevaPass !== this.repeatPass) {
        this.txtSnackClave = 'Las contraseñas no coinciden';
        this.snackClave = true;
      } else {
        const path = `${url}/cambiarClave`;
        axios.post(path, {
          id: this.$session.get('id'),
          token: this.$session.get('token'),
          datos: {
            clave: this.newPass,
          },
        })
          .then((response) => {
            if (response.data.msg === 'Ok') {
              this.modalClave = false;
              this.$fire({
                title: '¡Éxito!',
                text: 'Se cambió tu contraseña',
                type: 'success',
              });
            } else {
              this.$fire({
                title: '¡Atención!',
                text: 'Hubo un problema al intentar actualizar su contraseña. Intente nuevamente más tarde.',
                type: 'error',
              });
            }
          });
      }
    },
    generarBLote() {
      const path = `${url}/generarBoletinesXLote`;
      axios.post(path, {
        id: this.$session.get('id'),
        token: this.$session.get('token'),
        fechaDesde: this.fechaDBoletin,
        fechaHasta: this.fechaHBoletin,
      })
        .then((response) => {
          if (response.success) {
            console.log(response);
          }
        });
    },
  },
  mounted() {
    this.init();
  },
  watch: {
    fechaDesdeList() {
      this.fechaDesdeSList = this.formatFecha(this.fechaDesdeList);
    },
    fechaHastaList() {
      this.fechaHastaSList = this.formatFecha(this.fechaHastaList);
    },
    fechaDBoletinP() {
      this.fechaDBoletin = this.formatFecha(this.fechaDBoletinP);
    },
    fechaHBoletinP() {
      this.fechaHBoletin = this.formatFecha(this.fechaHBoletinP);
    },
  },
};
</script>
