<template>
  <v-app :style= "bgImage">
    <v-content>
      <router-view/>
    </v-content>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      bgImage: {
        /* eslint-disable global-require */
        backgroundImage: `url(${require('@/assets/background.png')})`,
        /* eslint-enable global-require */
      },
    };
  },
};
</script>
<style>
  #app {
    background-size: cover;
    background-position: center center;
  }
  body {
    font-family: 'Poppins', sans-serif;
  }
  .v-application {
   font-family: 'Poppins', sans-serif !important;
 }
</style>
