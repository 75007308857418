<template>
  <v-container>
    <v-card class="mx-auto cardInteract mb-2"
    :width="(esMobile)? '100%' : '80%'">
      <v-card-title>
        Datos de la carta de porte para el Transporte Ferroviario
        <v-spacer></v-spacer>
        <v-btn small rounded class="px-5" color="#4b4b4b" dark
        @click="vaciarForm()">VACIAR FORM</v-btn>
      </v-card-title>
      <v-form ref="form">
        <v-card-text>
          <v-row dense justify="center" align="center" >
            <v-col cols="12" class="py-0"><hr>
              <b>1. Datos de intervinientes en el traslado de granos.</b>
            </v-col>
            <v-col :lg = "esMobile?12:8" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Titular</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:7" class = "myCols">
                 <v-text-field outlined solo dense v-model="form.titular"
                class="smallInput" disabled></v-text-field>
              </v-col>
            </v-col>
            <v-col lg = "4" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">CUIT Nro</v-subheader>
              </v-col>
              <v-col cols="8" class = "myCols">
                 <v-text-field outlined solo dense v-model="form.titularcuit"
                class="smallInput" disabled></v-text-field>
              </v-col>
            </v-col>
            <!-- Intermediario -->
            <v-col :lg = "esMobile?12:8" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Intermediario</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:7" class = "myCols">
                <v-select outlined solo dense v-model="form.interm"
                class="smallInput" :items="comboIntermediario"
                item-text="nombre" item-value="nombre" v-if="!otroInterm"
                @change="change(form.interm, 'interm', comboIntermediario)"
                ></v-select>
                <v-text-field outlined solo dense v-model="form.intermNew"
                class="smallInput mr-1" v-if="otroInterm"></v-text-field>
                <v-btn icon v-if="otroInterm" small
                @click="otroInterm = false; form.interm = 0">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-col>
            <v-col lg = "4" class = "myColsInt">
              <v-col lg="4" class = "myCols">
                <v-subheader class="pa-0">CUIT Nro</v-subheader>
              </v-col>
              <v-col cols="8" class = "myCols">
                <v-text-field outlined solo dense v-model="form.intermcuit"
                class="smallInput" :rules="ruleCUITNO"></v-text-field>
              </v-col>
            </v-col>
            <!-- Remitente Comercial -->
            <v-col :lg = "esMobile?12:8" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Remitente Comercial</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:7" class = "myCols">
                <v-select outlined solo dense v-model="form.remcom"
                class="smallInput" :items="comboRemitente"
                item-text="nombre" item-value="nombre" v-if="!otroRemcom"
                @change="change(form.remcom, 'remcom', comboRemitente)"></v-select>
                <v-text-field outlined solo dense v-model="form.remcomNew"
                class="smallInput mr-1" v-if="otroRemcom"></v-text-field>
                <v-btn icon v-if="otroRemcom" small
                @click="otroRemcom = false; form.remcom = 0">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-col>
            <v-col lg = "4" class = "myColsInt">
              <v-col lg="4" class = "myCols">
                <v-subheader class="pa-0">CUIT Nro</v-subheader>
              </v-col>
              <v-col cols="8" class = "myCols">
                <v-text-field outlined solo dense v-model="form.remcomcuit"
                class="smallInput" :rules="ruleCUITNO"></v-text-field>
              </v-col>
            </v-col>
            <!-- Corredor Comprador -->
            <v-col :lg = "esMobile?12:8" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Corredor Comprador</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:7" class = "myCols">
                <v-select outlined solo dense v-model="form.corrcom"
                class="smallInput" :items="comboCorrComp"
                item-text="nombre" item-value="nombre" v-if="!otroCorrcom"
                @change="change(form.corrcom, 'corrcom', comboCorrComp)"></v-select>
                <v-text-field outlined solo dense v-model="form.corrcomNew"
                class="smallInput mr-1" v-if="otroCorrcom"></v-text-field>
                <v-btn icon v-if="otroCorrcom" small
                @click="otroCorrcom = false; form.corrcom = 0">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-col>
            <v-col lg = "4" class = "myColsInt">
              <v-col lg="4" class = "myCols">
                <v-subheader class="pa-0">CUIT Nro</v-subheader>
              </v-col>
              <v-col cols="8" class = "myCols">
                <v-text-field outlined solo dense v-model="form.corrcomcuit"
                class="smallInput" :rules="ruleCUITNO"></v-text-field>
              </v-col>
            </v-col>
            <!-- Mercado a término -->
            <v-col :lg = "esMobile?12:8" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Mercado a término</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:7" class = "myCols">
                <v-select outlined solo dense v-model="form.merter"
                class="smallInput" :items="comboMercado"
                item-text="nombre" item-value="nombre" v-if="!otroMerter"
                @change="change(form.merter, 'merter', comboMercado)"></v-select>
                <v-text-field outlined solo dense v-model="form.merterNew"
                class="smallInput mr-1" v-if="otroMerter"></v-text-field>
                <v-btn icon v-if="otroMerter" small
                @click="otroMerter = false; form.merter = 0">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-col>
            <v-col lg = "4" class = "myColsInt">
              <v-col lg="4" class = "myCols">
                <v-subheader class="pa-0">CUIT Nro</v-subheader>
              </v-col>
              <v-col cols="8" class = "myCols">
                <v-text-field outlined solo dense v-model="form.mertercuit"
                class="smallInput" :rules="ruleCUITNO"></v-text-field>
              </v-col>
            </v-col>
            <!-- Corredor vendedor -->
            <v-col :lg = "esMobile?12:8" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Corredor vendedor</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:7" class = "myCols">
                <v-select outlined solo dense v-model="form.corrvend"
                class="smallInput" :items="comboCorrVend"
                item-text="nombre" item-value="nombre" v-if="!otroCorrvend"
                @change="change(form.corrvend, 'corrvend', comboCorrVend)"></v-select>
                <v-text-field outlined solo dense v-model="form.corrvendNew"
                class="smallInput mr-1" v-if="otroCorrvend"></v-text-field>
                <v-btn icon v-if="otroCorrvend" small
                @click="otroCorrvend = false; form.corrvend = 0">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-col>
            <v-col lg = "4" class = "myColsInt">
              <v-col lg="4" class = "myCols">
                <v-subheader class="pa-0">CUIT Nro</v-subheader>
              </v-col>
              <v-col cols="8" class = "myCols">
                <v-text-field outlined solo dense v-model="form.corrvendcuit"
                class="smallInput" :rules="ruleCUITNO"></v-text-field>
              </v-col>
            </v-col>
            <!-- Entregador -->
            <v-col :lg = "esMobile?12:8" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Entregador</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:7" class = "myCols">
                <v-text-field outlined solo dense value="WEIMAN PABLO ARIEL"
                class="smallInput" disabled></v-text-field>
              </v-col>
            </v-col>
            <v-col lg = "4" class = "myColsInt">
              <v-col lg="4" class = "myCols">
                <v-subheader class="pa-0">CUIT Nro</v-subheader>
              </v-col>
              <v-col cols="8" class = "myCols">
                <v-text-field outlined solo dense value="20-23574156-4"
                class="smallInput" disabled></v-text-field>
              </v-col>
            </v-col>
            <!-- Destinatario -->
            <v-col :lg = "esMobile?12:8" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Destinatario</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:7" class = "myCols">
                <v-select outlined solo dense v-model="form.dest"
                class="smallInput" :items="comboDestinatario"
                item-text="nombre" item-value="nombre" v-if="!otroDest"
                @change="change(form.dest, 'dest', comboDestinatario)"></v-select>
                <v-text-field outlined solo dense v-model="form.destNew"
                class="smallInput mr-1" v-if="otroDest"></v-text-field>
                <v-btn icon v-if="otroDest" small
                @click="otroDest = false; form.dest = 0">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-col>
            <v-col lg = "4" class = "myColsInt">
              <v-col lg="4" class = "myCols">
                <v-subheader class="pa-0">CUIT Nro</v-subheader>
              </v-col>
              <v-col cols="8" class = "myCols">
                <v-text-field outlined solo dense v-model="form.destcuit"
                class="smallInput" :rules="ruleCUIT"></v-text-field>
              </v-col>
            </v-col>
            <!-- Destino -->
            <v-col :lg = "esMobile?12:8" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Destino</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:7" class = "myCols">
                <v-select outlined solo dense v-model="form.destino"
                class="smallInput" :items="comboDestino"
                item-text="nombre" item-value="nombre" v-if="!otroDestino"
                @change="change(form.destino, 'destino', comboDestino)"></v-select>
                <v-text-field outlined solo dense v-model="form.destinoNew"
                class="smallInput mr-1" v-if="otroDestino"></v-text-field>
                <v-btn icon v-if="otroDestino" small
                @click="otroDestino = false; form.destino = 0">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-col>
            <v-col lg = "4" class = "myColsInt">
              <v-col lg="4" class = "myCols">
                <v-subheader class="pa-0">CUIT Nro</v-subheader>
              </v-col>
              <v-col cols="8" class = "myCols">
                <v-text-field outlined solo dense v-model="form.destinocuit"
                class="smallInput" :rules="ruleCUIT"></v-text-field>
              </v-col>
            </v-col>
            <!-- Intermedio del flete -->
            <v-col :lg = "esMobile?12:8" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Intermediario del flete</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:7" class = "myCols">
                <v-text-field outlined solo dense v-model="form.intflete"
                class="smallInput"></v-text-field>
              </v-col>
            </v-col>
            <v-col lg = "4" class = "myColsInt">
              <v-col lg="4" class = "myCols">
                <v-subheader class="pa-0">CUIT Nro</v-subheader>
              </v-col>
              <v-col cols="8" class = "myCols">
                <v-text-field outlined solo dense v-model="form.intfletecuit"
                class="smallInput" :rules="ruleCUITNO"></v-text-field>
              </v-col>
            </v-col>
            <!-- Transportista -->
            <v-col :lg = "esMobile?12:8" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Transportista</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:7" class = "myCols">
                <v-select outlined solo dense v-model="form.transp"
                class="smallInput" :items="comboTransportista"
                item-text="nombre" item-value="nombre" v-if="!otroTransp"
                @change="change(form.transp, 'transp', comboTransportista)"
                ></v-select>
                <v-text-field outlined solo dense v-model="form.transpNew"
                class="smallInput mr-1" v-if="otroTransp"></v-text-field>
                <v-btn icon v-if="otroTransp" small
                @click="otroTransp = false; form.transp = 0;">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-col>
            <v-col lg = "4" class = "myColsInt">
              <v-col lg="4" class = "myCols">
                <v-subheader class="pa-0">CUIT Nro</v-subheader>
              </v-col>
              <v-col cols="8" class = "myCols">
                <v-text-field outlined solo dense v-model="form.transpcuit"
                class="smallInput" :rules="ruleCUIT"></v-text-field>
              </v-col>
            </v-col>
            <v-col cols="12"><hr>
              <b>2. Datos de los granos / especies transportados</b>
            </v-col>
            <v-col cols = "6" class = "myColsInt">
              <v-col lg="4" class = "myCols">
                <v-subheader class="pa-0">Cosecha</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:7" class = "myCols">
                <v-select outlined solo dense v-model="form.cosecha"
                class="smallInput" :rules="ruleObligatorio" :items="comboCosecha"
                item-text="nombre" item-value="nombre"></v-select>
              </v-col>
            </v-col>
            <v-col cols = "6" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Contrato</v-subheader>
              </v-col>
              <v-col cols="8" class = "myCols">
                <v-text-field outlined solo dense v-model="form.contrato"
                class="smallInput"></v-text-field>
              </v-col>
            </v-col>
            <v-col lg = "6" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Grano/Especie</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:7" class = "myCols">
                <v-select outlined solo dense v-model="form.granoesp"
                class="smallInput" :rules="ruleObligatorio" :items="comboGranos"
                item-text="nombre" item-value="nombre"></v-select>
              </v-col>
            </v-col>
            <v-col cols = "6" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Tipo</v-subheader>
              </v-col>
              <v-col cols="8" class = "myCols">
                <v-text-field outlined solo dense v-model="form.tipo"
                class="smallInput" :rules="ruleObligatorio"></v-text-field>
              </v-col>
            </v-col>
            <v-col :class = "esMobile?'':'myColsInt'">
              <v-radio-group row class="smallInput" v-model="form.chkcalidad">
                <v-radio dense class="smallInput" label="Declaración de Calidad">
                </v-radio>
                <v-radio dense class="smallInput" label="Conforme"></v-radio>
                <v-radio dense class="smallInput" label="Condicional"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" class="text-center mt-2">
              <b>PROCEDENCIA DE LA MERCADERIA</b>
            </v-col>
            <v-col cols="12" class = "myColsInt">
             <v-col :cols="esMobile?4:2" class = "myCols">
                <v-subheader class="pa-0">Dirección</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:10" class = "myCols">
                <v-text-field outlined solo dense v-model="form.procdir"
                class="smallInput" :rules="ruleObligatorio"></v-text-field>
              </v-col>
            </v-col>
            <v-col cols = "6" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Localidad</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:7" class = "myCols">
                <v-text-field outlined solo dense v-model="form.procloc"
                class="smallInput" :rules="ruleObligatorio"></v-text-field>
              </v-col>
            </v-col>
            <v-col cols = "6" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Provincia</v-subheader>
              </v-col>
              <v-col cols="8" class = "myCols">
                <v-text-field outlined solo dense v-model="form.procprov"
                class="smallInput" :rules="ruleObligatorio"></v-text-field>
              </v-col>
            </v-col>
            <v-col cols = "6" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Puesta a disposición</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:7" class = "myCols">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormatted"
                      v-bind="attrs" class="smallInput"
                      @keypress="onlyDate($event)"
                      v-on="on" outlined solo dense placeholder="dd/mm/yyyy"
                      @blur="date=parseDate(dateFormatted)"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title class="smallInput"
                  @input="menu1 = false" outlined solo dense locale="es-ES"
                  scrollable>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-col>
            <v-col cols = "6" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Hora</v-subheader>
              </v-col>
              <v-col cols="8" class = "myCols">
                <v-text-field outlined solo dense v-model="form.aDispHora"
                class="smallInput" :rules="ruleObligatorio"></v-text-field>
              </v-col>
            </v-col>
            <v-col cols = "6" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Fin de carga</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:7" class = "myCols">
                <v-menu
                  ref="menu2"
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateFormatted2"
                      v-bind="attrs" class="smallInput"
                      @keypress="onlyDate($event)"
                      v-on="on" outlined solo dense placeholder="dd/mm/yyyy"
                      @blur="date2=parseDate(dateFormatted2)"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date2" no-title
                  class="smallInput" scrollable
                  @input="menu2 = false" outlined solo dense locale="es-ES">
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-col>
            <v-col cols = "6" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Hora</v-subheader>
              </v-col>
              <v-col cols="8" class = "myCols">
                <v-text-field outlined solo dense v-model="form.finCargaHora"
                class="smallInput" :rules="ruleObligatorio"></v-text-field>
              </v-col>
            </v-col>
            <v-col cols = "6" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Intercambio a</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:7" class = "myCols">
                <v-text-field outlined solo dense v-model="form.intercambioA"
                class="smallInput"></v-text-field>
              </v-col>
            </v-col>
            <v-col cols = "6" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Lugar y Fecha</v-subheader>
              </v-col>
              <v-col cols="8" class = "myCols">
                <v-text-field outlined solo dense v-model="form.intercambioLyF"
                class="smallInput"></v-text-field>
              </v-col>
            </v-col>
            <v-col cols = "6" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Trasbordo a</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:7" class = "myCols">
                <v-text-field outlined solo dense v-model="form.trasbA"
                class="smallInput"></v-text-field>
              </v-col>
            </v-col>
            <v-col cols = "6" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Lugar y Fecha</v-subheader>
              </v-col>
              <v-col cols="8" class = "myCols">
                <v-text-field outlined solo dense v-model="form.trasbLyF"
                class="smallInput"></v-text-field>
              </v-col>
            </v-col>
            <v-col cols="12"><hr>
              <b>3. Lugar de destino de los granos</b>
            </v-col>
            <v-col cols="12" class = "myColsInt">
             <v-col :cols="esMobile?4:2" class = "myCols">
                <v-subheader class="pa-0">Dirección</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:10" class = "myCols">
                <v-text-field outlined solo dense v-model="form.destdir"
                class="smallInput" :rules="ruleObligatorio"></v-text-field>
              </v-col>
            </v-col>
            <v-col cols = "6" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Localidad</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:7" class = "myCols">
                <v-text-field outlined solo dense v-model="form.destloc"
                class="smallInput" :rules="ruleObligatorio"></v-text-field>
              </v-col>
            </v-col>
            <v-col cols = "6" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Provincia</v-subheader>
              </v-col>
              <v-col cols="8" class = "myCols">
                <v-text-field outlined solo dense v-model="form.destprov"
                class="smallInput" :rules="ruleObligatorio"></v-text-field>
              </v-col>
            </v-col>
            <v-col cols="12"><hr>
              <b>3. Datos del transporte</b>
            </v-col>
            <v-col cols="12" class = "myColsInt">
             <v-col :cols="esMobile?4:2" class = "myCols">
                <v-subheader class="pa-0">Pagador flete</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:10" class = "myCols">
                <v-text-field outlined solo dense v-model="form.pagflete"
                class="smallInput"></v-text-field>
              </v-col>
            </v-col>
            <v-col cols = "6" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Nro. Pedido</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:7" class = "myCols">
                <v-text-field outlined solo dense v-model="form.nroPedido"
                class="smallInput" :rules="ruleObligatorio"></v-text-field>
              </v-col>
            </v-col>
            <v-col cols = "6" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Nro. Operativo</v-subheader>
              </v-col>
              <v-col cols="8" class = "myCols">
                <v-text-field outlined solo dense v-model="form.nroOperativo"
                class="smallInput"></v-text-field>
              </v-col>
            </v-col>
            <v-col cols = "6" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Nro. Guía</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:7" class = "myCols">
                <v-text-field outlined solo dense v-model="form.nroGuia"
                class="smallInput" :rules="ruleObligatorio"></v-text-field>
              </v-col>
            </v-col>
            <v-col cols = "6" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Km. a recorrer</v-subheader>
              </v-col>
              <v-col :cols="8" class = "myCols">
                <v-text-field outlined solo dense v-model="form.kmrecorrer"
                class="smallInput" :rules="ruleObligatorio"></v-text-field>
              </v-col>
            </v-col>
            <v-col cols = "6" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Vagones despachados</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:7" class = "myCols">
                <v-text-field outlined solo dense v-model="form.vagonesDesp"
                class="smallInput"></v-text-field>
              </v-col>
            </v-col>
            <v-col cols = "6" class = "myColsInt">
              <v-col :cols="esMobile?6:4" class = "myCols">
                <v-subheader class="pa-0">Peso Total Desp.</v-subheader>
              </v-col>
              <v-col :cols="esMobile?6:8" class = "myCols">
                <v-text-field outlined solo dense v-model="form.pesoTotalDesp"
                class="smallInput"></v-text-field>
              </v-col>
            </v-col>
            <v-col cols="6"></v-col>
            <v-col cols="12"><hr>
              <b>Datos para el transporte ferroviario de granos</b>
            </v-col>
            <v-data-table
              :items="extra"
              :headers = "headers"
              hide-default-header
              hide-default-footer
              disable-pagination dense
              style="width: 100%; background: none"
            >
              <template #header="{ }">
                <thead class="v-data-table-header">
                  <tr>
                    <th v-for="(h,i) in headers" :key="i" :rowspan="h.children?1:2"
                      class="text-center parent-header td-border-style"
                      :colspan="h.children?h.children.length:1"
                    >
                      {{ h.text }}
                    </th>
                  </tr>
                  <tr>
                    <th v-for="(h1,i1) in getSubHeader(headers)" :key="i1"
                      class="text-center child-header td-border-style">
                      {{ h1.text }}
                    </th>
                  </tr>
                </thead>
              </template>
              <template v-slot:body>
                <tr v-for="(row, index) in extra" :key="index">
                  <td width="80">
                    <v-text-field outlined solo dense v-model="row.nroOrden"
                      class="smallInput"></v-text-field>
                  </td>
                  <td width="90">
                    <v-text-field outlined solo dense v-model="row.vagones"
                      class="smallInput"></v-text-field>
                  </td>
                  <td width="250">
                    <v-text-field outlined solo dense v-model="row.precintos"
                      class="smallInput"></v-text-field>
                  </td>
                  <td width="100">
                    <v-text-field outlined solo dense v-model="row.pesoBruto"
                      class="smallInput" @change="cambioPesos(row, index)"
                      @keypress="onlyNumbers($event)"></v-text-field>
                  </td>
                  <td width="100">
                    <v-text-field outlined solo dense v-model="row.pesoTara"
                      class="smallInput" @change="cambioPesos(row, index)"
                      @keypress="onlyNumbers($event)"></v-text-field>
                  </td>
                  <td width="100">
                    <v-text-field outlined solo dense v-model="row.pesoNeto"
                      class="smallInput" @change="cambioPesos(row, index)"
                      @keypress="onlyNumbers($event)"></v-text-field>
                  </td>
                  <td align="center" width="110">
                    <v-checkbox dense v-model="row.arribado"
                      class="smallInput mt-1 mx-auto" style="width: min-content"
                    ></v-checkbox>
                  </td>
                  <td align="center" width="110">
                    <v-checkbox dense v-model="row.descargado"
                      class="smallInput mt-1" style="width: min-content"
                    ></v-checkbox>
                  </td>
                  <td>
                    <v-tooltip top>
                      <template v-slot:activator = "{on, attrs }">
                        <v-btn icon v-if="index != 0" class="ml-auto"
                         @click="eliminarGrupo(index)" v-bind="attrs" v-on="on">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Eliminar</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-col cols="12" :class="esMobile?'myColsInt': ''">
                <v-btn small rounded class="px-5" color="#4b4b4b" dark
                 @click="agregarRegistro()">
                  Agregar registro
                </v-btn>
              </v-col>
            <v-col cols="12"><hr>
              <b>Declaración jurada</b>
            </v-col>
            <v-col cols = "6" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">Aclaración</v-subheader>
              </v-col>
              <v-col :cols="esMobile?8:7" class = "myCols">
                <v-text-field outlined solo dense v-model="form.aclarac"
                class="smallInput" :rules="ruleObligatorio"></v-text-field>
              </v-col>
            </v-col>
            <v-col cols = "6" class = "myColsInt">
              <v-col cols="4" class = "myCols">
                <v-subheader class="pa-0">D.N.I.</v-subheader>
              </v-col>
              <v-col cols="8" class = "myCols">
                <v-text-field outlined solo dense v-model="form.dni"
                class="smallInput" :rules="ruleObligatorio"></v-text-field>
              </v-col>
            </v-col>
            <v-col cols="12"><hr>
              <b>Seleccione su carta de porte en BLANCO</b>
            </v-col>
            <v-col cols = "6" class = "myColsInt">
              <v-col :cols="esMobile?4:3" class = "myCols">
                <v-subheader class="pa-0">Archivo PDF</v-subheader>
              </v-col>
              <v-col cols="8" class = "myCols">
                <v-file-input outlined solo dense v-model="file" color="black"
                class="smallInput" prepend-icon="mdi-file-pdf" accept=".pdf"
                @change="changeFile" @click:clear="deleteFile"></v-file-input>
              </v-col>
            </v-col>
            <v-col cols = "6" class = "myColsInt">
              <v-col :cols="esMobile?6:4" class = "myCols">
                <v-subheader class="pa-0">Seleccione rango</v-subheader>
              </v-col>
              <v-col :cols="esMobile?6:8" class = "myCols">
                <v-select outlined solo dense v-model="rango" id="rango"
                class="smallInput" :items="rangoPags"></v-select>
              </v-col>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn small rounded :class="esMobile?'':'px-5'" color="#4b4b4b" dark
          @click="vaciarForm()">
          Vaciar Form.</v-btn>
          <v-spacer></v-spacer>
          <v-btn small rounded :class="esMobile?'':'px-5'" color="#4b4b4b" dark
          @click="completarCarta()">
          Completar carta</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <MLoading v-if="loading"/>
  </v-container>
</template>
<style>
  .smallInput fieldset {
    border: 2px solid #ffbb00;
  }

  .smallInput.v-input--is-focused fieldset {
   border: 2px solid #ffbb00;
  }
  .form-control-Int {
    height: 34px;
    padding: 6px 12px;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ffbb00;
    -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    padding: 3px 3px;
    height: 21px;
    margin: 0 3px;
    margin-right: 5px;
    display: block;
    font-size: small;
    width: 100%
  }
  .smallInput.v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
    margin: 0;
    max-height: 28px;
    min-height: 28px !important;
    display: flex!important;
    align-items: center!important
  }
  .smallInput.v-text-field.v-text-field--enclosed .v-text-field__details {
    margin: 0px;
  }

  .smallInput.v-input--selection-controls {
    margin: 0px;
  }
  .myColsInt {
    display: block ruby;
    padding-top: 0px !important; padding-bottom: 0px !important;
  }
  .v-subheader {
    height: 40px;
  }
  .cardInteract {
    background: rgba(255,255,255,0.7) !important;
    overflow-y: scroll;
    max-height: 700px;
    transform: scale(0.95);
  }
  .smallInput.v-input--is-disabled {
    cursor: not-allowed !important;
    pointer-events: auto !important;
  }
  @media (max-width: 900px) {
   .myColsInt {
      display: contents;
      padding-top: 0px !important; padding-bottom: 0px !important;
   }
  }
</style>
<script>
import MLoading from '@/components/Cliente/MLoading.vue';
import axios from 'axios';
import url from '@/var.js';

export default {
  name: 'Vagones',
  title: 'Pablo Weiman - Cartas de Porte',
  components: {
    MLoading,
  },
  data() {
    return {
      menu1: false,
      menu2: false,
      date: '',
      date2: '',
      form: {
        titular: '',
        titularcuit: '',
        interm: 0,
        intermNew: '',
        intermcuit: '',
        remcom: 0,
        remcomNew: '',
        remcomcuit: '',
        corrcom: 0,
        corrcomNew: '',
        corrcomcuit: '',
        merter: 0,
        merterNew: '',
        mertercuit: '',
        corrvend: 0,
        corrvendNew: '',
        corrvendcuit: '',
        entreg: 'WEIMAN PABLO ARIEL',
        entregcuit: '20-23574156-4',
        dest: 0,
        destNew: '',
        destcuit: '',
        destino: 0,
        destinoNew: '',
        destinocuit: '',
        intflete: '',
        intfletecuit: '',
        transp: 0,
        transpNew: '',
        transpcuit: '',
        cosecha: 0,
        contrato: '',
        granoesp: 0,
        tipo: '',
        chkcalidad: 0,
        procdir: '',
        procloc: '',
        procprov: '',
        destdir: '',
        destloc: '',
        destprov: '',
        aDispFecha: '',
        aDispHora: '',
        finCargaFecha: '',
        finCargaHora: '',
        intercambioA: '',
        intercambioLyF: '',
        trasbA: '',
        trasbLyF: '',
        pagflete: '',
        nroPedido: '',
        nroOperativo: '',
        nroGuia: '',
        kmrecorrer: '',
        vagonesDesp: '',
        pesoTotalDesp: '',
        aclarac: '',
        dni: '',
      },
      extra: [
        {
          nroOrden: '',
          vagones: '',
          precintos: '',
          pesoBruto: '',
          pesoTara: '',
          pesoNeto: '',
          arribado: 0,
          descargado: 0,
        },
      ],
      headers: [
        {
          text: '', children: [{ text: 'N° Orden' }], width: 60,
        },
        {
          text: '', children: [{ text: 'Vagones' }], width: 60,
        },
        {
          text: 'Precintos',
          value: 'precintos',
          children: [
            { text: 'Id. / Nro / Color' },
          ],
          width: 100,
        },
        {
          text: 'Kilos',
          value: 'peso',
          divider: true,
          children: [
            { text: 'Bruto' }, { text: 'Tara' }, { text: 'Neto' },
          ],
          width: 250,
        },
        { text: '', children: [{ text: 'Arribado' }], width: 60 },
        { text: '', children: [{ text: 'Descargado' }], width: 60 },
        { text: '', children: [{ text: '' }], width: 60 },
      ],
      file: undefined,
      rango: 1,
      comboIntermediario: [],
      comboRemitente: [],
      comboCorrComp: [],
      comboMercado: [],
      comboCorrVend: [],
      comboDestinatario: [],
      comboDestino: [],
      comboTransportista: [],
      comboCosecha: [],
      comboGranos: [],
      rangoPags: [
        { text: '1 - 8', value: 1 },
        { text: '9 - 16', value: 2 },
        { text: '17 - 24', value: 3 },
        { text: '25 - 32', value: 4 },
        { text: '33 - 40', value: 5 },
        { text: '41 - 48', value: 6 },
      ],
      otroInterm: false,
      otroRemcom: false,
      otroCorrcom: false,
      otroMerter: false,
      otroCorrvend: false,
      otroDest: false,
      otroDestino: false,
      otroTransp: false,
      pathFile: '',
      ruleObligatorio: [
        (v) => !!v || 'Este campo es obligatorio.',
      ],
      ruleCUIT: [
        (v) => (!!v && this.validarCuit(v)) || 'La CUIT ingresada no es válida.',
      ],
      ruleCUITNO: [
        (v) => (this.validarCuit(v)) || 'La CUIT ingresada no es válida.',
      ],
      esMobile: this.isMobile(),
      loading: true,
      dateFormatted: '',
      dateFormatted2: '',
    };
  },
  methods: {
    cargarCombos() {
      this.loading = true;
      const path = `${url}/combosInteractiva`;
      axios.post(path, {
        id: this.$session.get('id'),
        token: this.$session.get('token'),
      })
        .then((response) => {
          this.form.titular = response.data.titular.nombre;
          this.form.titularcuit = response.data.titular.nrodoc;
          this.comboIntermediario = response.data.intermediario;
          this.comboIntermediario.unshift({ codigo: 1, nombre: 'Otro...' });
          this.comboRemitente = response.data.remitente;
          this.comboRemitente.unshift({ codigo: 1, nombre: 'Otro...' });
          this.comboCorrComp = response.data.corredorComprador;
          this.comboCorrComp.unshift({ codigo: 1, nombre: 'Otro...' });
          this.comboMercado = response.data.mercado;
          this.comboMercado.unshift({ codigo: 1, nombre: 'Otro...' });
          this.comboCorrVend = response.data.corredorVendedor;
          this.comboCorrVend.unshift({ codigo: 1, nombre: 'Otro...' });
          this.comboDestinatario = response.data.destinatario;
          this.comboDestinatario.unshift({ codigo: 1, nombre: 'Otro...' });
          this.comboDestino = response.data.destino;
          this.comboDestino.unshift({ codigo: 1, nombre: 'Otro...' });
          this.comboTransportista = response.data.transportista;
          this.comboTransportista.unshift({ codigo: 1, nombre: 'Otro...' });
          this.comboCosecha = response.data.cosecha;
          this.comboGranos = response.data.granos;
          this.loading = false;
        });
    },
    changeFile() {
      if (this.file !== undefined) {
        if (this.file.type === 'application/pdf') {
          const formData = new FormData();
          formData.append('file', this.file);
          formData.append('id', this.$session.get('id'));
          formData.append('token', this.$session.get('token'));
          const path = `${url}/uploadFile`;
          axios.post(path, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
            .then((response) => {
              if (response.data.success) {
                this.pathFile = response.data.file;
              } else {
                this.pathFile = '';
                this.file = undefined;
                this.$alert(response.data.msg, 'Atención', 'warning');
              }
            });
        } else {
          this.pathFile = '';
          this.file = undefined;
          this.$alert('El archivo debe ser pdf', 'Atención', 'warning');
        }
      }
    },
    deleteFile() {
      if (this.file !== undefined) {
        const path = `${url}/deleteFile`;
        axios.post(path, {
          id: this.$session.get('id'),
          token: this.$session.get('token'),
          path: this.pathFile,
        })
          .then(() => {
            this.pathFile = '';
          });
      }
    },
    change(selected, name, list) {
      const i = list.findIndex((item) => item.nombre === selected);
      if (list[i].codigo === 1) {
        this.insertNew(name);
      } else {
        this.updateCUIT(list[i].nrodoc, name);
      }
    },
    updateCUIT(cuit, name) {
      switch (name) {
        case 'interm':
          this.form.intermcuit = cuit; break;
        case 'remcom':
          this.form.remcomcuit = cuit; break;
        case 'corrcom':
          this.form.corrcomcuit = cuit; break;
        case 'merter':
          this.form.mertercuit = cuit; break;
        case 'corrvend':
          this.form.corrvendcuit = cuit; break;
        case 'dest':
          this.form.destcuit = cuit; break;
        case 'destino':
          this.form.destinocuit = cuit; break;
        case 'transp':
          this.form.transpcuit = cuit; break;
        case 'chofer':
          this.form.chofercuit = cuit; break;
        default:
      }
    },
    insertNew(name) {
      switch (name) {
        case 'interm':
          this.otroInterm = true; break;
        case 'remcom':
          this.otroRemcom = true; break;
        case 'corrcom':
          this.otroCorrcom = true; break;
        case 'merter':
          this.otroMerter = true; break;
        case 'corrvend':
          this.otroCorrvend = true; break;
        case 'dest':
          this.otroDest = true; break;
        case 'destino':
          this.otroDestino = true; break;
        case 'transp':
          this.otroTransp = true; break;
        case 'chofer':
          this.otroChofer = true; break;
        default:
      }
    },
    completarCarta() {
      if (this.$refs.form.validate()) {
        if (this.file === undefined) {
          this.$alert('Debe adjuntar la carta de porte a completar', 'Atención', 'warning');
        } else {
          this.sendDatos();
        }
      } else {
        this.$alert('Por favor, complete los campos obligatorios', 'Atención', 'warning');
      }
    },
    sendDatos() {
      this.form.aDispFecha = this.date;
      this.form.finCargaFecha = this.date2;
      const path = `${url}/completarVagon`;
      axios.get(path, {
        params: {
          id: this.$session.get('id'),
          token: this.$session.get('token'),
          path: this.pathFile,
          form: this.form,
          extra: this.extra,
          pags: this.rango,
        },
        responseType: 'arraybuffer',
      })
        .then((response) => {
          if (response.data.byteLength > 0) {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const url2 = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url2;
            const fileName = response.headers['content-disposition'].split('=')[1];
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
          } else {
            this.$alert('El rango seleccionado no existe en el archivo adjunto', 'Atención', 'warning');
          }
        });
    },
    validarCuit(cuit) {
      let cuitValido = true;
      let i;
      let r = 0;
      if (cuit === '') {
        cuitValido = true;
      } else {
        const ref = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2];
        const aux = cuit.replace(new RegExp('-', 'g'), '');
        const soloNro = cuit.replace(/[^0-9]/g, '');
        if (soloNro !== aux || aux.length !== 11) {
          cuitValido = false;
          return false;
        }
        for (i = 0; i < 10; i += 1) {
          r += parseInt(aux[i], 10) * ref[i];
        }
        r = 11 - (r % 11);
        if (r >= 10) {
          r = 0;
        }
        if (r !== parseInt(aux[10], 10)) {
          cuitValido = false;
        }
      }
      return cuitValido;
    },
    cambioPesos(item, index) {
      if (item.pesoTara !== '' && item.pesoBruto !== '') {
        const aux = item.pesoBruto - item.pesoTara;
        this.extra[index].pesoNeto = (Math.round(aux * 100) / 100).toString();
      }
    },
    onlyNumbers(event) {
      const keyCode = (event.keyCode ? event.keyCode : event.which);
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      } else {
        return true;
      }
      return false;
    },
    onlyDate(e) {
      const char = String.fromCharCode(e.keyCode);
      if (/^[0-9/]+$/.test(char)) {
        return true;
      }
      e.preventDefault();
      return false;
    },
    vaciarForm() {
      this.$refs.form.reset();
      this.$nextTick(() => {
        this.form.chkcalidad = 0;
        this.rango = 1;
        this.extra = [{
          nroOrden: '',
          vagones: '',
          precintos: '',
          pesoBruto: '',
          pesoTara: '',
          pesoNeto: '',
          arribado: 0,
          descargado: 0,
        }];
      });
    },
    isMobile() {
      if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || (window.innerWidth < 800)) {
        return true;
      }
      return false;
    },
    agregarRegistro() {
      if (this.extra.length < 40) {
        this.extra.push({
          nroOrden: '',
          vagones: '',
          precintos: '',
          pesoBruto: '',
          pesoTara: '',
          pesoNeto: '',
          arribado: 0,
          descargado: 0,
        });
      } else {
        this.$alert('Solo se pueden agregar hasta 40 vagones', 'Atención', 'warning');
      }
    },
    eliminarGrupo(i) {
      this.extra.splice(i, 1);
    },
    formatDate(date) {
      if (!date) return date;
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return date;
      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    getSubHeader(headers) {
      let result = [];
      headers
        .filter((i) => i.children)
        .forEach((v) => {
          result = result.concat(v.children);
        });
      return result;
    },
  },
  mounted() {
    this.cargarCombos();
  },
  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
    date2() {
      this.dateFormatted2 = this.formatDate(this.date2);
    },
  },
};
</script>
