<template>
  <v-app>
    <v-toolbar color="#000000"
      :src="img" height="600"
      dark prominent>
      <v-row>
        <v-col cols="1" class="d-none d-md-block"></v-col>
        <v-col :cols="(esMobile?12:3)">
          <v-img src = "@/assets/Weiman_logotipo.svg">
          </v-img>
        </v-col>
        <v-col cols="12" class="d-flex d-sm-none text-center">
          <v-menu offset-y dark>
            <template v-slot:activator="{ on }">
                <v-btn absolute icon x-large color="#ffbb00" class="mx-auto" v-on="on"
                  style="left:0; right: 0;">
                  <v-icon>mdi-menu</v-icon></v-btn>
            </template>
            <v-list>
                <v-list-item color="white" href="/">Inicio</v-list-item>
                <v-list-item color="#ffbb00" href="/institucional">Institucional</v-list-item>
                <v-list-item color="white" href="/servicios">Servicios</v-list-item>
                <v-list-item color="white" href="/contacto">Contacto</v-list-item>
                <v-list-item color="#ffbb00" href="/login">Acceso a clientes</v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="8" class="mt-10 d-none d-sm-flex">
          <v-spacer></v-spacer>
          <v-btn text color="white" href="/">Inicio</v-btn>
          <v-btn text color="#ffbb00" href="/institucional">Institucional</v-btn>
          <v-btn text color="white" href="/servicios">Servicios</v-btn>
          <v-btn text color="white" href="/contacto">Contacto</v-btn>
          <v-btn text color="#ffbb00" href="/login">Acceso a clientes
            <v-icon right>mdi-account-group</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-col :cols="esMobile?12:4"
        :class= "esMobile?'text-center colTitle': 'colTitle'">
        PROTEGIENDO EL FRUTO DE NUESTRA TIERRA.
      </v-col>
    </v-toolbar>
    <div style="background-color: white;" class="mb-8">
      <v-col cols="12" class="text-center mb-10 tituloFlecha">
        <v-img
          src="@/assets/iconoFlecha.svg"
          :aspect-ratio = "esMobile?10:30"
          contain
        ></v-img>
        <hr class="vr mx-auto">
        <v-btn rounded class="btnServicios px-10">La empresa</v-btn>
      </v-col>
      <v-card class ="mx-auto" max-width="950" flat>
        <v-container>
          <v-row justify="space-between">
            <v-col :cols="(esMobile) ? 12 : 6">
              <v-img src="@/assets/instit/la_empresa.png">
              </v-img>
            </v-col>
            <v-col :cols="(esMobile) ? 12 : 6" class="text-justify pr-5">
              <p>Somos una empresa familiar en segunda generación. Contando con casi
               40 años de experiencia, dedicación y personal capacitado, para llevar
               adelante algo que nos apasiona: <b>Proteger con empeño el fruto de
               nuestra tierra.</b></p>
              <p>La mezcla que proporciona la juventud con la experiencia adquirida
                a través de los años y la <b>visión de un gran futuro</b>, nos
              impulsa a crecer e innovar en forma permanente para el beneficio de
            nuestros clientes.</p>
              <p>El futuro nos aguarda, <b>esperemos poder caminarlo juntos,
              aprender y ayudar</b> a todas las firmas que confian en lo que
                consideramos es el servicio de nuestra empresa:<br>
                <b>Ser el socio en el que depositan toda su confianza, para llegar a
                un buen puerto.</b></p>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
    <div class="mb-8 greyBackground">
      <v-col cols="12" class="text-center my-10">
        <v-btn rounded class="btnServicios px-10">Nuestra misión y visión</v-btn>
      </v-col>
      <v-card class ="mx-auto greyBackground" max-width="950" flat>
        <v-container>
          <v-row justify="space-between">
            <v-col :cols="(esMobile) ? 12 : 5" class="text-justify pr-5">
              <p><b>Ser un eslabón fundamental de las empresas que confían en
              nosotros</b> el producto de su tierra, su trabajo y su futuro,
            para encontrar de manera rápida y ágil, la mejor solución.</p>
            <b>Nuestro compromiso:</b><br><br>
            <p>La búsqueda de <b>soluciones inteligentes, rápidas y efectivas</b>
              para todos los problemas que surgen de manera constante en este
            trabajo.</p>
            <p>Ser referente del sector agrícola, reconocido por la calidad del
            servicio.</p>
            </v-col>
            <v-col :cols="(esMobile) ? 12 : 7">
              <v-img src="@/assets/instit/mision.png">
              </v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
    <Footer/>
  </v-app>
</template>
<style>
.tituloFlecha {
  margin-top: -2%;
  padding-top: 0px;
}
hr.vr {
  width: 1px;
  height: 10vh;
  border-left: 1px solid;
  margin: 0px;
}

@media screen and (max-width: 600px) {
   .tituloFlecha {
    margin-top: -5%
   }
}
</style>
<script>
import Footer from '@/components/General/Footer.vue';

export default {
  name: 'Institucional',
  components: {
    Footer,
  },
  data() {
    return {
      esMobile: this.isMobile(),
      /* eslint-disable global-require */
      img: this.isMobile() ? require('@/assets/instit/mobile/slide.png') : require('@/assets/instit/slide.png'),
      /* eslint-enable global-require */
    };
  },
  methods: {
    isMobile() {
      if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || (window.innerWidth < 1000)) {
        return true;
      }
      return false;
    },
  },
};
</script>
