<template>
  <div>
    <Header/>
    <v-container fill-height>
      <v-tabs fixed-tabs v-model="tabs" background-color="rgba(255,255,255,0.7)"
      style="max-width: 75% !important" class="mx-auto" color="#4b4b4b">
        <v-tab dark href="#camion">Camión
        </v-tab>
        <v-tab dark href="#vagon">Vagón</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabs" style="background: none">
        <v-tab-item :key="1" value="camion" transition="false" reverse-transition="false">
          <Camiones/>
        </v-tab-item>
        <v-tab-item :key="2" value="vagon" transition="false" reverse-transition="false">
          <Vagones/>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <Footer/>
  </div>
</template>
<script>
import Header from '@/components/Cliente/Header.vue';
import Footer from '@/components/Cliente/Footer.vue';
import Camiones from '@/components/Cliente/Camiones.vue';
import Vagones from '@/components/Cliente/Vagones.vue';

export default {
  name: 'Interactiva',
  title: 'Pablo Weiman - Cartas de Porte',
  components: {
    Header,
    Footer,
    Camiones,
    Vagones,
  },
  data() {
    return {
      tabs: 'camion',
    };
  },
};
</script>
