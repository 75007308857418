<template>
  <v-app>
    <v-toolbar color="#000000" :src="img"
      height="600" dark prominent>
      <v-row>
          <v-col cols="1" class="d-none d-md-block"></v-col>
          <v-col :cols="(esMobile?12:3)">
            <v-img src = "@/assets/Weiman_logotipo.svg">
            </v-img>
          </v-col>
          <v-col cols="12" class="d-flex d-sm-none text-center">
          <v-menu offset-y dark>
            <template v-slot:activator="{ on }">
                <v-btn absolute icon x-large color="#ffbb00" class="mx-auto" v-on="on"
                  style="left:0; right: 0;">
                  <v-icon>mdi-menu</v-icon></v-btn>
            </template>
            <v-list>
                <v-list-item color="white" href="/">Inicio</v-list-item>
                <v-list-item color="#ffbb00" href="/institucional">Institucional</v-list-item>
                <v-list-item color="white" href="/servicios">Servicios</v-list-item>
                <v-list-item color="white" href="/contacto">Contacto</v-list-item>
                <v-list-item color="#ffbb00" href="/login">Acceso a clientes</v-list-item>
            </v-list>
          </v-menu>
        </v-col>
          <v-col cols="8" class="mt-10 d-none d-sm-flex">
            <v-spacer></v-spacer>
            <v-btn text color="white" href="/">Inicio</v-btn>
            <v-btn text color="white" href="/institucional">Institucional</v-btn>
            <v-btn text color="#ffbb00" href="/servicios">Servicios</v-btn>
            <v-btn text color="white" href="/contacto">Contacto</v-btn>
            <v-btn text color="#ffbb00" href="/login">Acceso a clientes
              <v-icon right>mdi-account-group</v-icon>
            </v-btn>
          </v-col>
       </v-row>
      <v-col cols="esMobile?12:6" :class= "esMobile?'text-center colTitle': 'colTitle'">
        <span style="color: #ffbb00">
          SOLUCIONAR E INFORMAR
        </span>
          CUALQUIER <br>REQUERIMIENTO DE MANERA INMEDIATA
      </v-col>
    </v-toolbar>
    <div style="background-color: white" class="mb-8">
      <v-col cols="12" class="text-center mb-10 tituloFlecha">
        <v-img
          src="@/assets/iconoFlecha.svg"
          :aspect-ratio = "esMobile?10:30"
          contain
        ></v-img>
        <hr class="vr mx-auto">
        <v-btn rounded class="btnServicios px-10">Servicios</v-btn>
      </v-col>
      <v-card class ="mx-auto" max-width="950" flat>
        <v-container id="entreg">
          <v-row justify="space-between">
            <v-col :cols="(esMobile) ? 12 : 6">
              <v-img src="@/assets/serv/entregas.png">
              </v-img>
            </v-col>
            <v-col :cols="(esMobile) ? 12 : 6">
              <v-container fill-height>
                <v-row>
                  <h3 style="color:#ffbb00">ENTREGAS Y EMBARQUES</h3>
                  <p>Nos dedicamos a la recepción, entrega y embarque de cereales,
                  oleaginosas y subproductos en el puerto de Bahía Blanca.</p>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-card class ="mx-auto" max-width="950" flat>
        <v-container id="ases">
          <v-row justify="space-between">
            <v-col :cols="(esMobile) ? 12 : 6">
              <v-img src="@/assets/serv/personalizado.png">
              </v-img>
            </v-col>
            <v-col :cols="(esMobile) ? 12 : 6">
              <v-container fill-height>
                <v-row>
                  <h3 style="color:#ffbb00">ASESORAMIENTO PERSONALIZADO</h3>
                  <p>Estamos permanentemente informados para buscar junto a nuestros
                  clientes la mejor solución brindando nuestro compromiso,
                  conocimiento y experiencia.</p>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-card class ="mx-auto" max-width="950" flat>
        <v-container id = "aten">
          <v-row justify="space-between">
            <v-col :cols="(esMobile) ? 12 : 6">
              <v-img src="@/assets/serv/24hs.png">
              </v-img>
            </v-col>
            <v-col :cols="(esMobile) ? 12 : 6">
              <v-container fill-height>
                <v-row>
                  <h3 style="color:#ffbb00">TRABAJAMOS LAS 24 HORAS</h3>
                  <p>Estamos trabajando las 24 horas al servicio de nuestros
                  clientes.<br>
                  Con personal calificado, dando la información permanente de
                    todo el proceso, desarrollo y control del movimiento de la
                    mercadería.</p>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-card class ="mx-auto" max-width="950" flat>
        <v-container id="control">
          <v-row justify="space-between">
            <v-col :cols="(esMobile) ? 12 : 6">
              <v-img src="@/assets/serv/controlDoc.png">
              </v-img>
            </v-col>
            <v-col :cols="(esMobile) ? 12 : 6">
              <v-container fill-height>
                <v-row>
                  <h3 style="color:#ffbb00">CONTROL DE DOCUMENTACIÓN Y CUPO</h3>
                  <p>Nuestra empresa cuenta con una oficina abierta en forma
                  permanente en el punto de concentración de ingreso de todos
                  los camiones que arriban al puerto de Bahía Blanca para
                  solucionar e informar cualquier requerimiento de manera inmediata.
                </p>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-card class="mx-auto" flat :max-width="(esMobile) ? '100%' : '50%'">
        <v-img src="@/assets/serv/Grupo 116.svg" class="ma-10">
        </v-img>
      </v-card>
    </div>
    <Footer/>
  </v-app>
</template>
<style>
.colTitle {
  display: inline-block;
  position: absolute;
  left: 10%;
  top: 50%;
  font-size: x-large;
}
@media screen and (max-width: 600px) {
  .colTitle {
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    font-size: x-large;
  }
}
</style>
<script>
import Footer from '@/components/General/Footer.vue';

export default {
  name: 'Servicios',
  components: {
    Footer,
  },
  data() {
    return {
      esMobile: this.isMobile(),
      /* eslint-disable global-require */
      img: this.isMobile() ? require('@/assets/serv/mobile/slide.png') : require('@/assets/serv/slide.png'),
      /* eslint-enable global-require */
    };
  },
  methods: {
    goTo(path) {
      this.$vuetify.goTo(`#${path}`);
    },
    isMobile() {
      if ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) || (window.innerWidth < 1000)) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    if (this.$router.currentRoute.path.split('#').length > 1) {
      const ruta = this.$router.currentRoute.path.split('#')[1];
      if (ruta !== '') {
        this.goTo(ruta);
      }
    }
  },
};
</script>
