<template>
  <div>
    <Header/>
    <v-container class="containerInicio">
      <v-row v-if = "!isMobile()">
        <v-col class="d-flex" md = "2">
          <v-menu
            v-model="menu1"
            nudge-left
            transition="scale-transition"
            offset-y class="menuPicker"
            min-width="290px"
            :close-on-content-click = "false"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="fechaDesdeString"
                outlined
                filled dark
                max-height = "100px"
                append-icon="event"
                @blur = "fechadesde = parseDate(fechaDesdeString)"
                v-on="on"
                readonly
                class = "inputDate textPicker"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fechaDesde"
              locale ="es" scrollable
              no-title dark class="datePicker"
              @input = "menu1= false">
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col  class="d-flex" md = "2">
          <v-menu
            v-model="menu2"
            transition="scale-transition"
            offset-y
            class="menuPicker"
            min-width="290px"
            :close-on-content-click = "false"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="fechaHastaString"
                outlined
                filled dark
                append-icon="event"
                @blur ="fechahasta = parseDate(fechaHastaString)"
                v-on="on"
                readonly
                class = "inputDate textPicker"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fechaHasta"
              dark class="datePicker"
              locale ="es" scrollable
              value ="dd-mm-YYYY"
              @input = "menu2 = false"
              no-title>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col md = "1">
          <v-select
            v-model = "tipoNro"
            :items = "tiposNros"
          ></v-select>
        </v-col>
        <v-col md = "2">
          <v-text-field
            v-model = "numero"
            label = "Número"
            @keydown.enter = "cargarDatos()"
          ></v-text-field>
        </v-col>
        <v-col md = "3">
          <v-select
            v-model = "granoSel"
            :items = "granos"
            item-text = "nombre"
            item-value = "codigo"
            label = "Grano"
            no-data-text = "No hay granos"
          ></v-select>
        </v-col>
        <v-col md = "2">
          <v-btn
            rounded
            color = "#000000"
            class = "mt-3 btnEspecial"
            @click = "cargarDatos()"
          >
            Actualizar
            <v-icon right>mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-else dense>
        <v-col class="d-flex" cols = "6">
          <v-menu
            ref = "menu1"
            v-model="menu1"
            :closeOnContentClick="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="fechaDesdeString"
                outlined
                filled
                max-height = "100px"
                append-icon="event"
                @blur = "fechadesde = parseDate(fechaDesdeString);"
                v-on="on"
                readonly
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fechaDesde"
              no-title
              @input = "menu1 = false">
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="d-flex" cols = "6">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="fechaHastaString"
                outlined
                filled
                append-icon="event"
                @blur ="fechaHasta = parseDate(fechaHastaString)"
                v-on="on"
                readonly
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fechaHasta"
              locale ="es"
              value ="dd-mm-YYYY"
              @input = "menu2 = false"
              no-title>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols = "3">
          <v-select
            v-model = "tipoNro"
            :items = "tiposNros"
          ></v-select>
        </v-col>
        <v-col cols = "9">
          <v-text-field
            v-model = "numero"
            label = "Número"
            @keydown.enter = "cargarDatos()"
          ></v-text-field>
        </v-col>
        <v-col cols = "12">
          <v-select
            v-model = "granoSel"
            :items = "granos"
            item-text = "nombre"
            item-value = "codigo"
            label = "Grano"
            no-data-text = "No hay granos"
          ></v-select>
        </v-col>
        <v-col cols = "12">
          <v-btn
            color = "primary"
            class = "mt-3"
            width = "100%"
            @click = "cargarDatos()"
          >
            Actualizar
          </v-btn>
        </v-col>
      </v-row>
      <v-data-table
        :headers = "isMobile() ? headersMobile : headers"
        :items = "items"
        :items-per-page = "itemsPerPage"
        locale = "es"
        :page.sync = "pagActual"
        item-key = "codigo"
        :server-items-length = "cantElementos"
        dense
        @update:page="paginate"
        @click:row = "handleClick"
        @update:sort-desc = "sortDesc"
        @page-count = "lengthPags = $event"
        :style = "{ background: `transparent` }"
        hide-default-footer
        calculate-widths
        @update:sort-by='updateSortBy'
        :sort-by="columnaSorted"
      >
        <template slot = "items" slot-scope = "props">
          <tr @click = "handleClick(props.item)">
            <td class = "text-xs-right mr-2">{{ props.item.numeroFinal }}</td>
            <td>{{ props.item.fechadescarga }}</td>
            <td>{{ props.item.nombreremitente }}</td>
            <td>{{ props.item.nombrecorredor }}</td>
            <td class = "text-xs-right">
            {{ props.item.nombredestinatario }}</td>
            <td>{{ props.item.nombredestino }}</td>
            <td>{{ props.item.nombregrano }}</td>
            <td>{{ props.item.patentecamion }}</td>
            <td>{{ props.item.neto }}</td>
            <td></td>
          </tr>
        </template>
        <template v-if="isMobile()" v-slot:item.nroctg="{ item }">
          <b>{{item.nroctg}}</b>
        </template>
        <template v-slot:item.acciones="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon small class = "mr-2" @click="info(item)" v-on = "on"
              >
                mdi-information-variant
              </v-icon>
            </template>
            <span>Información de Carta de Porte</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon class = "v-icon v-icon--link mr-2 myIcon"
              @click="descargarArchivo(item, 'cp', 'ctg')" v-on = "on">
                <Icon icon="ant-design:file-pdf-outlined"/>
              </v-btn>
            </template>
            <span>Descargar Carta de Porte</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon small class = "mr-2 mlIcon"
                @click="descargarArchivo(item, 'bt', 'btg')" v-on = "on">
                mdi-file-document-outline
              </v-icon>
            </template>
            <span>Descargar boletin</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon small class = "mr-2"
                @click="descargarArchivo(item, 'tk', 'tkg')" v-on = "on">
                mdi-file-outline
              </v-icon>
            </template>
            <span>Descargar ticket</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination v-model="pagActual" :length="lengthPags"></v-pagination>
      </div>
    </v-container>
    <Footer class=" myFooter"/>
    <div class = "formVer">
      <v-dialog v-model = "modalVer" width = "900px">
        <Form v-if = "!isMobile()"
          v-bind:item = "item" v-model = "modalVer">
        </Form>
        <FormMobile v-else v-bind:item = "item" v-model = "modalVer">
        </FormMobile>
      </v-dialog>
    </div>
  </div>
</template>
<style>
  .myFooter {
    left: 0; right: 0; bottom: 0;
  }
  .inputDate {
    transform: scale(0.9);
  }
  .v-data-table td {
    padding: 0 2px !important;
  }

  .v-data-table th {
    padding: 0 1px !important;
    font-size: 0.875rem !important;
  }
  .btnEspecial, .textPicker, .datePicker, .menuPicker {
    color: white !important;
  }

  .btnEspecial {
    background-color: rgba(0,0,0,0.7) !important;
  }

  .textPicker fieldset {
    background-color: black;
    opacity: 0.7;
  }

  .v-dialog {
    border-radius: 10px;
    border: 2px solid #0246ff;
  }

  .cardListados {
    opacity: 0.9;
  }
  .containerInicio {
    background: #fffbfbd1;
    border-left: 2px double #0246ff;
    border-right: 2px double #0246ff;
  }
  .v-data-table .v-data-table__mobile-table-row .myIcon {
    width: 16px !important;
    height: 16px !important;
    font-size: 21px;
  }

  .myIcon {
    width: 16px !important;
    height: 16px !important;
    font-size: 16px !important;
  }

  tbody tr.v-data-table__mobile-table-row:nth-of-type(odd) {
    background-color: rgba(255,255,255, 1);
  }

  .v-data-table .v-data-table__mobile-table-row {
    display: table-row !important;
  }

  .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
    min-height: 30px !important;
  }

  .v-data-table .v-data-table__mobile-table-row .v-icon{
    font-size: 24px !important
  }

  .v-data-table .v-data-table__mobile-table-row .mlIcon{
    margin-left: 3px;
  }
</style>
<script>
import axios from 'axios';
import Form from '@/components/Cliente/Form.vue';
import FormMobile from '@/components/Cliente/FormMobile.vue';
import Header from '@/components/Cliente/Header.vue';
import Footer from '@/components/Cliente/Footer.vue';
import url from '@/var.js';
import { Icon } from '@iconify/vue2'; // eslint-disable-line import/no-extraneous-dependencies

export default {
  name: 'Inicio',
  title: 'Pablo Weiman - Cartas de Porte',
  components: {
    Form,
    FormMobile,
    Header,
    Footer,
    Icon,
  },
  data() {
    return {
      registrado: (this.$session.get('id') !== 1),
      nombre: '',
      menu1: false,
      menu2: false,
      fechaDesde: new Date().toISOString().substr(0, 10),
      fechaHasta: new Date().toISOString().substr(0, 10),
      fechaDesdeString: this.formatFecha(new Date().toISOString().substr(0, 10)),
      fechaHastaString: this.formatFecha(new Date().toISOString().substr(0, 10)),
      numero: '',
      tiposNros: [{ text: 'CP', value: 'cp' }, { text: 'CTG', value: 'ctg' }],
      tipoNro: 'cp',
      granoSel: -1,
      granos: [],
      headers: [
        {
          text: 'Número', value: 'numeroFinal', align: 'end', class: 'mx-2',
        },
        {
          text: 'CTG', value: 'nroctg', align: 'end',
        },
        {
          text: 'F. Desc.', value: 'fechadescarga', align: 'start',
        },
        {
          text: 'Titular', value: 'titular',
        },
        {
          text: 'Corredor', value: 'corredorvtasecun',
        },
        {
          text: 'Destinatario', value: 'nombredestinatario',
        },
        {
          text: 'Destino', value: 'nombredestino',
        },
        {
          text: 'Grano', value: 'nombregrano',
        },
        {
          text: 'Pat', value: 'patentecamion',
        },
        {
          text: 'Neto', value: 'neto',
        },
        {
          text: 'Acciones', value: 'acciones', sortable: false, width: '100',
        },
      ],
      headersMobile: [
        {
          text: 'CTG', value: 'nroctg', align: 'end', class: 'font-weight-bold',
        },
        {
          text: 'Número', value: 'numeroFinal', align: 'end', class: 'mx-2',
        },
        {
          text: 'F. Desc.', value: 'fechadescarga', align: 'start',
        },
        {
          text: 'Titular', value: 'titular',
        },
        {
          text: 'Corredor', value: 'corredorvtasecun',
        },
        {
          text: 'Destinatario', value: 'nombredestinatario',
        },
        {
          text: 'Destino', value: 'nombredestino',
        },
        {
          text: 'Grano', value: 'nombregrano',
        },
        {
          text: 'Pat', value: 'patentecamion',
        },
        {
          text: 'Neto', value: 'neto',
        },
        {
          text: 'Acciones', value: 'acciones', sortable: false, width: '100',
        },
      ],
      items: [],
      pagActual: 1,
      columnaSorted: ['numeroFinal'],
      lengthPags: 0,
      itemsPerPage: 30,
      cantElementos: 0,
      modalVer: false,
      clickB: false,
      item: [],
      desc: false,
    };
  },
  methods: {
    init() {
      if (this.$session.get('id') == null) {
        this.$router.push('/');
        this.$router.go(0);
      } else if (this.registrado) {
        const path = `${url}/getIniciales`;
        axios.post(path, {
          id: this.$session.get('id'),
          token: this.$session.get('token'),
        })
          .then((response) => {
            this.nombre = response.data.nombre;
            this.granos = response.data.granos;
            this.granos.push({ codigo: -1, nombre: 'Todos' });
          });
        this.cargarDatos();
      }
    },
    formatFecha(date) {
      if (!date) {
        return null;
      }
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) {
        return null;
      }
      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    convertirFechaBd(date) {
      return this.formatFecha(new Date(date).toISOString().substr(0, 10));
    },
    isMobile() {
      return (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
    },
    cargarDatos() {
      this.loading = true;
      const path = `${url}/cargarDatos`;
      axios.post(path, {
        id: this.$session.get('id'),
        token: this.$session.get('token'),
        filtros: {
          fDesde: this.fechaDesde,
          fHasta: this.fechaHasta,
          tipoNro: this.tipoNro,
          numero: this.numero,
          grano: this.granoSel,
        },
        pag: this.pagActual,
        orderBy: this.columnaSorted[0],
        desc: this.desc,
      })
        .then((response) => {
          const final = [];
          (response.data.datos).forEach((val) => {
            const aux = {
              codigo: val.codigo,
              nroctg: val.nroctg,
              fechadescarga: this.convertirFechaBd(val.fechadescarga),
              titular: val.titular,
              corredorvtasecun: val.corredorvtasecun,
              nombredestinatario: val.nombredestinatario,
              nombredestino: val.nombredestino,
              nombregrano: val.nombregrano,
              patentecamion: val.patentecamion,
              numero: val.numero,
              prefijo: val.prefijo,
              neto: val.neto,
              numeroFinal: `${val.prefijo}-${val.numero}`,

            };
            final.push(aux);
          });
          this.items = final;
          this.cantElementos = response.data.cantTotal;
          this.lengthPags = Math.ceil(response.data.cantTotal / this.itemsPerPage);
        });
      this.loading = false;
    },
    paginate(val) {
      this.cargarDatos();
      this.pagActual = val;
    },
    info(item) {
      this.clickB = true;
      this.modalVer = true;
      const path = `${url}/cargarInfo`;
      axios.post(path, {
        id: this.$session.get('id'),
        token: this.$session.get('token'),
        item: item.codigo,
      })
        .then((response) => {
          const val = response.data.datos;
          this.item = {
            codigo: val.codigo,
            prefijo: val.prefijo,
            numero: val.numero,
            prefijoreemplazada: (val.prefijoreemplazada == null) ? '0' : val.prefijoreemplazada,
            numeroreemplazada: (val.numeroreemplazada == null) ? '0' : val.numeroreemplazada,
            fechaimputacion: this.convertirFechaBd(val.fechaimputacion),
            nombretitular: this.acomodarTexto(val.nombretitular),
            nombrertecomprod: this.acomodarTexto(val.nombrertecomprod),
            nombredestinatario: this.acomodarTexto(val.nombredestinatario),
            nombredestino: this.acomodarTexto(val.nombredestino),
            nombrertecomvtaprim: this.acomodarTexto(val.nombrertecomvtaprim),
            nombrertecomvtasecun: this.acomodarTexto(val.nombrertecomvtasecun),
            nombrertecomvtasecun2: this.acomodarTexto(val.nombrertecomvtasecun2),
            nombrecorredorvtaprim: this.acomodarTexto(val.nombrecorredorvtaprim),
            nombrecorredorvtasecun: this.acomodarTexto(val.nombrecorredorvtasecun),
            nombrecorreinterno: this.acomodarTexto(val.nombrecorreinterno),
            cupo: val.cupo == null ? '' : val.cupo,
            nrorecibo: val.nrorecibo,
            nrocontrato: this.acomodarTexto(val.nrocontrato),
            nroctg: (val.nroctg == null) ? '0' : val.nroctg,
            nombregrano: this.acomodarTexto(val.nombregrano),
            nombrecosecha: this.acomodarTexto(val.nombrecosecha),
            nombrecalidad: this.acomodarTexto(val.nombrecalidad),
            nombrelocalidproced: this.acomodarTexto(val.nombrelocalidproced),
            kmarecorrer: val.kmarecorrer,
            preciotn: this.acomodarNumero(val.preciotn),
            fechacarga: this.convertirFechaBd(val.fechacarga),
            pesobrutocarga: val.pesobrutocarga,
            taracarga: val.taracarga,
            pesonetocarga: val.pesonetocarga,
            fechadescarga: this.convertirFechaBd(val.fechadescarga),
            pesobrutodescarga: val.pesobrutodescarga,
            taradescarga: val.taradescarga,
            pesonetodescarga: val.pesonetodescarga,
            patentecamion: this.acomodarTexto(val.patentecamion),
            patenteacoplado: this.acomodarTexto(val.patenteacoplado),
            nombrechofer: this.acomodarTexto(val.nombrechofer),
            nombretipotransporte: this.acomodarTexto(val.nombretipotransporte),
            nombretransporte: this.acomodarTexto(val.nombretransporte),
            nombrelocaliddestino: this.acomodarTexto(val.nombrelocaliddestino),
            porchumedad: this.acomodarNumero(val.porchumedad),
            porcmermahumedad: this.acomodarNumero(val.porcmermahumedad),
            porcotrasmermas: this.acomodarNumero(val.porcotrasmermas),
            otrasmermas: val.otrasmermas,
            porcmermamanipuleo: val.porcmermamanipuleo,
            mermahumedad: val.mermahumedad,
            descripotrasmermas: this.acomodarTexto(val.descripotrasmermas),
            netofinal: val.pesonetodescarga - val.otrasmermas - val.mermahumedad,
            observleyenda: this.acomodarTexto(val.leyendas),
          };
          this.clickB = false;
        });
    },
    acomodarNumero(n) {
      return (n == null) ? '0.0' : parseFloat(n).toFixed(2);
    },
    acomodarTexto(s) {
      return (s == null || s === '') ? ' ' : s;
    },
    descargarArchivo(item, tipoArchivo1, tipoArchivo2) {
      this.loading = true;
      this.clickB = true;
      const path = `${url}/descargarArchivo`;
      axios.post(path, {
        id: this.$session.get('id'),
        token: this.$session.get('token'),
        numero: item.numeroFinal,
        codigo: item.codigo,
        tipo1: tipoArchivo1,
        tipo2: tipoArchivo2,
      })
        .then((response) => {
          if (response.data.msg !== 'Ok') {
            this.$alert('Documento no disponible', '¡Atención!', 'warning');
          } else {
            window.open(response.data.response);
          }
          this.clickB = false;
          this.loading = false;
        });
    },
    handleClick(val) {
      if (!this.clickB) {
        this.info(val);
      }
    },
    sortDesc(bool) {
      this.pagActual = 1;
      if (bool.length !== 0) {
        [this.desc] = bool;
      }
      this.cargarDatos();
    },
    updateSortBy(val) {
      this.columnaSorted = val;
      this.cargarDatos();
    },
  },
  watch: {
    fechaDesde() {
      this.fechaDesdeString = this.formatFecha(this.fechaDesde);
    },
    fechaHasta() {
      this.fechaHastaString = this.formatFecha(this.fechaHasta);
    },
  },
  mounted() {
    this.init();
  },
};
</script>
